import { Button, Form, Select, Input, Radio, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";

const AgregarVehiculo = () => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [showOwner, setShowOwner] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const openNotificationWithIcon = (type, title, msg) => {
    api[type]({
      message: title,
      description: msg,
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const tokenValid = await auth.currentUser.getIdToken(true);

      const response = true;
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Vehiculo agregado",
          `El usuario ${values.name} se guardo exitosamente.`
        );
        form.resetFields();
        setTimeout(() => {
          navigate("/vehiculos/agregar", { replace: true });
        }, 5000);
      } else {
        openNotificationWithIcon(
          "warning",
          "Vehiculo no guardado",
          response.error.msg ? response.error.msg : response.error
        );
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Vehiculo no guardado",
        `El usuario ${values.name} no se guardo exitosamente.`
      );
      console.error("Error: ", error);
    }
    setLoading(false);
  };

  const handlePuestoChange = (value) => {
    if (value === "Vendedor en Ruta") {
      setShowOwner(true);
    } else {
      setShowOwner(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Title level={3} className="ccg-center">
        Agregar Vehículo
      </Title>
      <Form
        form={form}
        style={{ maxWidth: 600, margin: "auto", textAlign: "center" }}
        name="agregar-vehiculo"
        onFinish={onFinish}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
      >
        <Form.Item
          label="Nombre(s)"
          name="nombre"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su(s) nombre(s)",
            },
          ]}
        >
          <Input placeholder="Ingrese un nombre su(s) nombre(s)" />
        </Form.Item>
        <Form.Item
          label="Apellido Paterno"
          name="ap_paterno"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su apellido paterno",
            },
          ]}
        >
          <Input placeholder="Ingrese un su apellido paterno" />
        </Form.Item>
        <Form.Item
          label="Apellido Paterno"
          name="ap_materno"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su apellido materno",
            },
          ]}
        >
          <Input placeholder="Ingrese su apellido materno" />
        </Form.Item>

        <Form.Item
          label="Correo"
          name="email"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el correo",
            },
            {
              type: "email",
              message: "Por favor ingresa un correo electrónico válido",
            },
          ]}
        >
          <Input type="email" placeholder="Ingrese un correo del vehiculo" />
        </Form.Item>

        <Form.Item
          label="Contraseña"
          name="password"
          rules={[
            {
              required: true,
              message: "Por favor ingresa la contraseña",
            },
            {
              min: 8,
              message: "La contraseña debe tener al menos 8 caracteres",
            },
            {
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#¿])[A-Za-z\d@$!%*?&#¿]{8,}$/,
              message:
                "La contraseña debe incluir al menos una mayúscula, una minúscula, un número y un carácter especial (@$!%*?&#¿)",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Puesto"
          name="position"
          rules={[
            { required: true, message: "Por favor selecciona un puesto" },
          ]}
        >
          <Select defaultValue={"def"} onChange={handlePuestoChange}>
            <Option value="def">Selecciona un puesto</Option>
            <Option value="Administrador">Administrador</Option>
            <Option value="Chofer">Chofer</Option>
            <Option value="Laboratorista">Vendedor local</Option>
            <Option value="Secretaria">Vendedor en ruta</Option>
            <Option value="Supervisor">Supervisor</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Sucursal"
          name="branch"
          rules={[
            { required: true, message: "Por favor selecciona una sucursal" },
          ]}
        >
          <Select defaultValue={"0"}>
            <Option value="0">Selecciona una sucursal</Option>
            <Option value="Centro">Centro</Option>
            <Option value="Playa del Carmen">Playa del Carmen</Option>
          </Select>
        </Form.Item>
        {showOwner && (
          <Form.Item
            label="Dueño de bodega"
            name="warehouse"
            rules={[
              {
                required: true,
                message: "Por favor ingrese si es dueño de una bodega",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="true">Si</Radio>
              <Radio value="false">No</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AgregarVehiculo;
