import React, { useState, useEffect } from "react";
import { List, Button, Spin, Row, Col, Input, Select } from "antd";
import ChatComponent from "./ChatComponent";
import { getAll } from "../../servicios/solicitudes/servicios";
import { auth } from "../../firebase";
import Title from "antd/es/typography/Title";

const BuscarSolicitud = () => {
  const { Option } = Select;
  const [solicitudes, setSolicitudes] = useState([]);
  const [filteredSolicitudes, setFilteredSolicitudes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const [searchId, setSearchId] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    const fetchConversations = async () => {
      setLoading(true);
      const validedToken = await auth.currentUser.getIdToken(true);
      const body = {
        sucursal: "Centro"
      }
      const response = await getAll(validedToken, body);
      if (response.success) {
        setSolicitudes(response.info);
        setFilteredSolicitudes(response.info);
      }
      setLoading(false);
    };

    fetchConversations();
  }, []);

  const filterSolicitudes = () => {
    let data = solicitudes;
    if (searchId) {
      data = data.filter(
        (solicitud) =>
          solicitud.folio.includes(searchId) ||
          solicitud.correo.includes(searchId)
      );
    }
    if (statusFilter) {
      data = data.filter((solicitud) => solicitud.estatus === statusFilter);
    }
    setFilteredSolicitudes(data);
  };

  useEffect(() => {
    filterSolicitudes();
  }, [searchId, statusFilter]);

  if (loading) {
    return <Spin tip="Cargando solicitudes..." />;
  }

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={12}>
          <Row>
            <Col xs={24}>
              <Title level={2} className="ccg-center">
                Solicitudes
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={16} lg={16}>
              <Input
                placeholder="Buscar por Folio"
                value={searchId}
                onChange={(e) => setSearchId(e.target.value)}
              />
            </Col>
            <Col xs={24} md={8} lg={8}>
              <Select
                placeholder="Filtrar por estado"
                value={statusFilter}
                onChange={(value) => setStatusFilter(value)}
                style={{ width: "45%" }}
              >
                <Option value="">Todos</Option>
                <Option value="Activo">Activo</Option>
                <Option value="Pendiente">Pendiente</Option>
                <Option value="Respondida">Respondida</Option>
                <Option value="Cerrado">Cerrado</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={24}>
              <List
                itemLayout="horizontal"
                dataSource={filteredSolicitudes}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button onClick={() => setSelectedConversation(item)}>
                        Ver mensajes
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={`Folio: ${item.folio}`}
                      description={`Correo: ${item.correo} | Estado: ${item.estatus}`}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} lg={12}>
          {selectedConversation && (
            <ChatComponent solicitudInfo={selectedConversation} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default BuscarSolicitud;
