import React, { useState, useEffect } from "react";
import {
  Table,
  Switch,
  Modal,
  Form,
  Input,
  Button,
  Space,
  Col,
  Row,
} from "antd";
import Sucursales from "../sucursales/Sucursales";
import Puestos from "../puestos/Puestos";
import { firestore } from "../../firebase";
import { auth } from "../../firebase";
import { collection, onSnapshot, getDocs } from "firebase/firestore";
import Title from "antd/es/typography/Title";
import { actualizarStatus } from "../../servicios/capacitaciones/servicios";
import { useNotification } from "../NotificationContext";

const BuscarCapacitacion = () => {
  const [normas, setNormas] = useState([]);
  const [procedimientos, setProcedimientos] = useState([]);
  const [sucursal, setSucursal] = useState(null);
  const [puestos, setPuestos] = useState([]);
  const [editingNorma, setEditingNorma] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  const openNotificationWithIcon = useNotification();

  // Obtener puestos y áreas
  useEffect(() => {
    if (!sucursal) return;

    const puestosRef = collection(firestore, `sucursal/${sucursal}/puesto`);
    const unsubscribe = onSnapshot(puestosRef, async (snapshot) => {
      const nuevosPuestos = [];

      if (snapshot.empty) {
        setPuestos([]);
      } else {
        for (const doc of snapshot.docs) {
          const puesto = {
            id: doc.id,
            ...doc.data(),
            areas: [],
          };
  
          // Consultar áreas del puesto
          const areasRef = collection(
            firestore,
            `sucursal/${sucursal}/puesto/${doc.id}/areas`
          );
          const areasSnapshot = await getDocs(areasRef);
          if (!areasSnapshot.empty) {
            for (const areaDoc of areasSnapshot.docs) {
              puesto.areas.push({
                id: areaDoc.id,
                ...areaDoc.data(),
              });
            }
          }
  
          nuevosPuestos.push(puesto);
        }
        setPuestos(nuevosPuestos);
      }

    });

    return () => unsubscribe();
  }, [sucursal]);

  // Obtener normas y procedimientos de los puestos
  useEffect(() => {
    if (!sucursal || puestos.length === 0) {
      setNormas([]);
      setProcedimientos([]);
      return;
    }
  
    let unsubscriptions = [];
  
    const updateNormas = (puestoId, nuevasNormas) => {
      setNormas((prevNormas) => {
        const normasMap = new Map(prevNormas.map((norma) => [norma.key, norma]));
        nuevasNormas.forEach((norma) => {
          normasMap.set(norma.key, norma);
        });
        return Array.from(normasMap.values());
      });
    };
    
    const updateProcedimientos = (puestoId, nuevosProcedimientos) => {
      setProcedimientos((prevProcedimientos) => {
        const procedimientosMap = new Map(prevProcedimientos.map((procedimiento) => [procedimiento.key, procedimiento]));
        nuevosProcedimientos.forEach((procedimiento) => {
          procedimientosMap.set(procedimiento.key, procedimiento);
        });
        return Array.from(procedimientosMap.values());
      });
    };
  
    puestos.forEach((puesto) => {
      const puestoId = puesto.id;
  
      if (!puesto.areas || !puesto.areas.length) {
        console.log("Obteniendo info de puesto: ", puesto);
        const normasRef = collection(firestore, `sucursal/${sucursal}/puesto/${puestoId}/norma`);
        const procedimientosRef = collection(firestore, `sucursal/${sucursal}/puesto/${puestoId}/procedimiento`);
  
        const normasUnsub = onSnapshot(normasRef, (snapshot) => {
          const nuevasNormas = snapshot.docs.map((doc) => ({
            key: `${doc.id}-${puestoId}`,
            tipo: "norma",
            puesto: puestoId,
            ...doc.data(),
          }));
          updateNormas(puestoId, nuevasNormas);
        });
  
        const procedimientosUnsub = onSnapshot(procedimientosRef, (snapshot) => {
          const nuevosProcedimientos = snapshot.docs.map((doc) => ({
            key: `${doc.id}-${puestoId}`,
            tipo: "procedimiento",
            puesto: puestoId,
            ...doc.data(),
          }));
          updateProcedimientos(puestoId, nuevosProcedimientos);
        });
  
        unsubscriptions.push(normasUnsub, procedimientosUnsub);
      } else {
        puesto.areas.forEach((area) => {
          const areaId = area.id;
          console.log("Obteniendo normas y procedimientos de área: ", areaId);
          const normasRef = collection(firestore, `sucursal/${sucursal}/puesto/${puestoId}/areas/${areaId}/norma`);
          const procedimientosRef = collection(firestore, `sucursal/${sucursal}/puesto/${puestoId}/areas/${areaId}/procedimiento`);
  
          const normasUnsub = onSnapshot(normasRef, (snapshot) => {
            const nuevasNormas = snapshot.docs.map((doc) => ({
              key: `${doc.id}-${areaId}`,
              tipo: "norma",
              puesto: puestoId,
              area: areaId,
              ...doc.data(),
            }));
            setNormas((prevNormas) => {
              const filteredNormas = prevNormas.filter((norma) => !(norma.puesto === puestoId && norma.area === areaId));
              return [...filteredNormas, ...nuevasNormas];
            });
          });
  
          const procedimientosUnsub = onSnapshot(procedimientosRef, (snapshot) => {
            const nuevosProcedimientos = snapshot.docs.map((doc) => ({
              key: `${doc.id}-${areaId}`,
              tipo: "procedimiento",
              puesto: puestoId,
              area: areaId,
              ...doc.data(),
            }));
            setProcedimientos((prevProcedimientos) => {
              const filteredProcedimientos = prevProcedimientos.filter((procedimiento) => !(procedimiento.puesto === puestoId && procedimiento.area === areaId));
              return [...filteredProcedimientos, ...nuevosProcedimientos];
            });
          });
  
          unsubscriptions.push(normasUnsub, procedimientosUnsub);
        });
      }
    });
  
    return () => unsubscriptions.forEach((unsub) => unsub());
  }, [sucursal, puestos]);
  

  const handleEdit = (record) => {
    setEditingNorma(record);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setEditingNorma(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditingNorma(null);
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Clave",
      dataIndex: "clave",
      key: "clave",
    },
    {
      title: "Puesto",
      dataIndex: "puesto",
      key: "puesto",
    },
    {
      title: "Versión",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const status = record.estatus === "Activo" ? true : false;
        return (
          <Switch
            checked={status}
            onChange={(checked) => handleStatusChange(record, checked)}
          />
        );
      },
    },
    {
      title: "Creación",
      dataIndex: "creacion",
      key: "creacion",
    },
    {
      title: "Opciones",
      key: "opciones",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)}>Editar</Button>
        </Space>
      ),
    },
  ];

  const handleStatusChange = async (record, checked) => {
    console.log('actalizando status: ', record);
    const body = {
      id: record.key.split('-')[0],
      status: checked ? "Activo" : "Inactivo",
      tipo: record.tipo,
      puesto: record.puesto,
      sucursal,
    };

    if (record.area && record.area.length) body['areas']= [record.area]
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await actualizarStatus(token, body);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Éxito",
          `Se ${body.estatus} documento ${record.nombre}.`
        );
      } else {
        openNotificationWithIcon(
          "warning",
          "Algo salió mal",
          response.error.msg ? response.error.msg : response.error
        );
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Algo salió mal",
        `La capacitación ${record.nombre} no se ${body.estatus.toLowerCase()}.`
      );
      console.error("Error: ", error);
    }
  };

  const filteredData = [...normas, ...procedimientos].filter((item) => {
    const lowerCaseSearchText = searchText.toLowerCase();
    return (
      item.nombre.toLowerCase().includes(lowerCaseSearchText) ||
      item.clave.toLowerCase().includes(lowerCaseSearchText) ||
      item.version.toLowerCase().includes(lowerCaseSearchText) ||
      item.puesto.toLowerCase().includes(lowerCaseSearchText)
    );
  });

  return (
    <>
      <Title level={3} className="ccg-center">
        Biblioteca
      </Title>
      <Row gutter={8}>
        <Col xs={24} md={12} lg={8}>
          <Sucursales onSucursalChange={(value) => setSucursal(value)} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Input
            placeholder="Buscar por nombre, puesto o clave"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="id"
        locale={{
          emptyText: "No hay documentos",
          filterConfirm: "Aceptar",
          filterReset: "Reiniciar",
          selectAll: "Seleccionar todo",
          selectInvert: "Invertir selección",
          sortTitle: "Ordenar",
          filterTitle: "Filtro",
          filterEmptyText: "No hay filtros",
          triggerDesc: "Ordenar descendente",
          triggerAsc: "Ordenar ascendente",
          cancelSort: "Cancelar",
        }}
      />
      {editingNorma && (
        <Modal
          open={isModalVisible}
          title="Editar Capacitación"
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        >
          <Form
            initialValues={editingNorma}
            onFinish={(values) => {
              // Save changes logic here
              console.log(values);
              handleModalOk();
            }}
          >
            <Form.Item
              name="nombre"
              label="Nombre"
              rules={[
                { required: true, message: "Por favor ingrese el nombre" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="clave"
              label="Clave"
              rules={[
                { required: true, message: "Por favor ingrese la clave" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="version"
              label="Versión"
              rules={[
                { required: true, message: "Por favor ingrese la versión" },
              ]}
            >
              <Input />
            </Form.Item>
            <Puestos sucursal={sucursal} />
          </Form>
        </Modal>
      )}
    </>
  );
};

export default BuscarCapacitacion;
