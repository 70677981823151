import React, { useState } from 'react';
import { Steps, Button, Form, Input, Select } from 'antd';
import Title from 'antd/es/typography/Title';

const { Step } = Steps;
const { Option } = Select;

const AgregarProyecto = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const handleChange = (values) => {
    setFormData({ ...formData, ...values });
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = () => {
    console.log('Datos del formulario:', formData);
    // Aquí puedes enviar los datos del formulario a tu backend o hacer lo que necesites
  };

  const steps = [
    {
      title: 'Paso 1',
      content: (
        <Form onFinish={handleNext}>
          <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Siguiente
          </Button>
        </Form>
      ),
    },
    {
      title: 'Paso 2',
      content: (
        <Form onFinish={handleNext}>
          <Form.Item name="city" label="Ciudad" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="country" label="País" rules={[{ required: true }]}>
            <Select>
              <Option value="USA">Estados Unidos</Option>
              <Option value="UK">Reino Unido</Option>
              <Option value="Canada">Canadá</Option>
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Siguiente
          </Button>
        </Form>
      ),
    },
    {
      title: 'Resumen',
      content: (
        <div>
          <p>Nombre: {formData.name}</p>
          <p>Email: {formData.email}</p>
          <p>Ciudad: {formData.city}</p>
          <p>País: {formData.country}</p>
          <Button type="primary" onClick={handleFinish}>
            Enviar
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Title level={3} className="ccg-center">Agrear Proyecto</Title>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <div style={{ marginTop: '20px' }}>
        {steps[currentStep].content}
        <div style={{ marginTop: '20px' }}>
          {currentStep > 0 && (
            <Button style={{ marginRight: 8 }} onClick={handlePrev}>
              Anterior
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={handleNext}>
              Siguiente
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgregarProyecto;
