import React from "react";
import { Form, Input, Button, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";

const { Option } = Select;

const AgregarCarga = () => {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log("Form values: ", values);
    message.success("Carga registrada con éxito");
  };

  const handleUpload = (file) => {
    console.log("Uploaded file: ", file);
  };

  return (
    <>
      <Title level={3} className="ccg-center">
        Registrar Carga de Gasolina
      </Title>
      <Form
        form={form}
        style={{ maxWidth: 600, margin: "auto", textAlign: "center" }}
        onFinish={handleFinish}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
      >
        <Form.Item
          name="vehicle"
          label="Vehículo"
          rules={[
            { required: true, message: "Por favor seleccione un vehículo" },
          ]}
        >
          <Select placeholder="Seleccione un vehículo">
            <Option value="vehiculo1">Vehículo 1</Option>
            <Option value="vehiculo2">Vehículo 2</Option>
            <Option value="vehiculo3">Vehículo 3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="initialMileage"
          label="Kilometraje Inicial"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el kilometraje inicial",
            },
          ]}
        >
          <Input type="number" placeholder="Kilometraje Inicial" />
        </Form.Item>

        <Form.Item
          name="finalMileage"
          label="Kilometraje Final"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el kilometraje final",
            },
          ]}
        >
          <Input type="number" placeholder="Kilometraje Final" />
        </Form.Item>

        <Form.Item
          name="liters"
          label="Litros"
          rules={[{ required: true, message: "Por favor ingrese los litros" }]}
        >
          <Input type="number" placeholder="Litros" />
        </Form.Item>

        <Form.Item
          name="price"
          label="Total $:"
          rules={[
            { required: true, message: "Por favor ingrese el precio a pagar" },
          ]}
        >
          <Input type="number" placeholder="Precio a Pagar" />
        </Form.Item>

        <Form.Item
                name="ticket"
                label="Foto del Ticket"
                rules={[{ required: true, message: 'Por favor suba la foto del ticket' }]}
            >
                <input 
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(e) => handleUpload(e.target.files[0])}
                />
            </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Registrar Carga
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AgregarCarga;
