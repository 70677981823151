import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useAuth } from "../Context";
import { firestore } from "../../firebase";
import VerCapacitacion from "./VerCapacitacion";
import NoPermisos from "../NoPermisos";
import Title from "antd/es/typography/Title";
import { collection, onSnapshot } from "firebase/firestore";

const Procedimientos = () => {
  const { currentUser, role, sucursal, permisos, isAdmin } = useAuth();
  const [employeeData, setEmployeeData] = useState({ puesto: "", areas: [] });
  const [procedimientos, setProcedimientos] = useState([]);

  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = firestore
      .collection("sucursal")
      .doc(sucursal)
      .collection("empleado")
      .doc(currentUser.uid)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setEmployeeData({
              puesto: data.puesto || "",
              areas: data.areas || [],
            });
          } else {
            console.log("No tiene puesto asignado el empleado!");
          }
        },
        (error) => {
          console.log("Error getting document:", error);
        }
      );

    return () => unsubscribe();
  }, [currentUser, role, sucursal]);

  useEffect(() => {
    if (!employeeData.puesto && employeeData.areas.length === 0) return;
  
    const unsubscriptions = [];
  
    const fetchProcedimientos = async () => {
      try {
        // Consultar procedimientos del puesto
        if (employeeData.areas.length > 0) {
          for (const area of employeeData.areas) {
            const areaRef = collection(
              firestore,
              `sucursal/${sucursal}/puesto/${employeeData.puesto}/areas/${area}/procedimiento`
            );
  
            const areaUnsub = onSnapshot(areaRef, (procSnap) => {
              const procs = procSnap.docs.map((doc) => ({
                key: doc.id,
                tipo: "norma",
                area: area.id,
                ...doc.data(),
              }));
              setProcedimientos((prevProcs) =>
                prevProcs
                  .filter((proc) => proc.area !== area.id)
                  .concat(procs).filter(item => item.estatus === 'Activo')
              );
            });
            unsubscriptions.push(areaUnsub);
          }
        } else {
          const puestoRef = collection(
            firestore,
            `sucursal/${sucursal}/puesto/${employeeData.puesto}/procedimiento`
          );
  
          const puestoUnsub = onSnapshot(puestoRef, (procSnap) => {
            const procs = procSnap.docs.map((doc) => ({
              key: doc.id,
              tipo: "norma",
              ...doc.data(),
            }));
            setProcedimientos(procs.filter(item => item.estatus === 'Activo'));
          });
          unsubscriptions.push(puestoUnsub);
        }
      } catch (error) {
        console.log("Error fetching procedimientos:", error);
      }
    };
  
    fetchProcedimientos();
  
    return () => {
      unsubscriptions.forEach((unsub) => unsub());
    };
  }, [employeeData.puesto, employeeData.areas, sucursal, isAdmin]);
  

  return (
    <>
      {isAdmin || permisos.capacitacion.procedimientos ? (
        <>
          <Title level={3} className="ccg-center">
            Mis Procedimientos
          </Title>
          <Row gutter={8}>
            {procedimientos.map((procedimiento, index) => (
              <Col key={index} xs={24} md={12} lg={6}>
                <VerCapacitacion documento={procedimiento} />
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <>
          <NoPermisos />
        </>
      )}
    </>
  );
};

export default Procedimientos;
