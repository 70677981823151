import React, { useState } from "react";
import { Form, Input, DatePicker, Button, Radio } from "antd";
import moment from "moment";
import "moment/locale/es";
import { useAuth } from "../Context";
import { useNotification } from "../NotificationContext";
import { guardarSolicitud } from "../../servicios/recursosHumanos/servicios";
import { auth } from "../../firebase";
import Title from "antd/es/typography/Title";

moment.locale("es");
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const AgregarSolicitud = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tipoSolicitud, setTipoSolicitud] = useState("permiso");
  const openNotificationWithIcon = useNotification();
  const { currentUser, sucursal } = useAuth();

  const disabledDate = (current) => {
    return (
      current && (current < moment().startOf("day") || current.day() === 0)
    );
  };

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const body = {
        empleado: currentUser.uid,
        tipo: tipoSolicitud,
        sucursal
      };

      if (tipoSolicitud === "permiso") {
        body.fecha = values.fecha.format("YYYY-MM-DD");
        body.motivo = values.motivo;
      } else if (tipoSolicitud === "vacaciones") {
        body.fechaInicio = values.rangoFechas[0].format("YYYY-MM-DD");
        body.fechaFin = values.rangoFechas[1].format("YYYY-MM-DD");
      }

      const tokenValid = await auth.currentUser.getIdToken(true);
      const response = await guardarSolicitud(tokenValid, body);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Solicitud agregada",
          `${tipoSolicitud} solicitado con éxito.`
        );
        form.resetFields();
      } else {
        openNotificationWithIcon(
          "warning",
          "Algo salio mal",
          response.error.msg ? response.error.msg : response.error
        );
        showErrorMessages(response.errors);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Solicitud no guardada",
        `Intente de nuevo o consulte con su administrador`
      );
      console.error("Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const showErrorMessages = (errorObject) => {
    if (!errorObject || !Array.isArray(errorObject)) {
      console.error("Invalid error object structure");
      return;
    }

    errorObject.forEach((error) => {
      const field = document.getElementById(error.field);
      if (field) {
        const errorMessage = document.createElement("div");
        errorMessage.className = "error-message"; // Puedes agregar estilos CSS para esta clase
        errorMessage.innerText = error.error;

        // Limpiar errores anteriores si existen
        const existingError = field.nextSibling;
        if (existingError && existingError.className === "error-message") {
          existingError.remove();
        }

        field.parentNode.insertBefore(errorMessage, field.nextSibling);
      } else {
        console.warn(`Field with id ${error.field} not found`);
      }
    });
  };

  return (
    <>
    <Title level={3} className="ccg-center">Nueva Solicitud</Title>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item name="tipo" label="Tipo de solicitud" initialValue="permiso">
          <Radio.Group onChange={(e) => setTipoSolicitud(e.target.value)}>
            <Radio value="permiso">Permiso</Radio>
            <Radio value="vacaciones">Vacaciones</Radio>
          </Radio.Group>
        </Form.Item>

        {tipoSolicitud === "permiso" && (
          <Form.Item
            name="fecha"
            label="Fecha del permiso"
            rules={[
              { required: true, message: "Por favor selecciona una fecha" },
            ]}
          >
            <DatePicker
              disabledDate={disabledDate}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}

        {tipoSolicitud === "vacaciones" && (
          <Form.Item
            name="rangoFechas"
            label="Rango de fechas de vacaciones"
            rules={[
              {
                required: true,
                message: "Por favor selecciona un rango de fechas",
              },
            ]}
          >
            <RangePicker
              disabledDate={disabledDate}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}

        {tipoSolicitud === "permiso" && (
          <Form.Item
            name="motivo"
            label="Motivo del permiso"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el motivo del permiso",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Describe el motivo del permiso" />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Solicitar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AgregarSolicitud;
