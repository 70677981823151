import React, { useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { auth } from "../firebase";

// Crea el contexto
const AuthContext = React.createContext();

export function useAuth(){
  return useContext(AuthContext);
}

// Crea el proveedor del contexto
export function AuthProvider(props) {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [permisos, setPermisos] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setToken(user.getIdToken(true));
        const decodedToken = jwtDecode(await user.getIdToken());
        console.log('decoded token: ', decodedToken);
        setRole(decodedToken.role);
        setSucursal(decodedToken.sucursal);
      }
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [])

  const value = {
    token,
    role,
    sucursal,
    setToken,
    currentUser, 
    setCurrentUser,
    loading,
    permisos,
    setPermisos,
    isAdmin,
    setIsAdmin
  }

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext };