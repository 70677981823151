import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import "./../Login.css";
import { LoginOutlined } from "@ant-design/icons";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./Context";

const { Title } = Typography;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { setToken, setCurrentUser } = useAuth();

  const navigate = useNavigate();

  const onFinish = async () => {
    setLoading(true);
    setButtonDisabled(true);
    try {
      const userCredential = await auth.signInWithEmailAndPassword(
        email,
        password
      );
      const user = userCredential.user;
      const token = await user.getIdToken();
      setToken(token);
      setCurrentUser(user);
      navigate("/sistema/bienvenida");
    } catch (error) {
      setLoading(false); // Ocultar loader después de iniciar sesión (ya sea éxito o fallo)
      setButtonDisabled(false);
      console.error("Error al iniciar sesión:", error.message);
      setErrorMessage("Credenciales incorrectas."); // Actualiza el mensaje de error
    }
  };

  return (
    // <AuthProvider>
    <div className="tbody">
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-content">
            <Title className="txt-center mb-5" level={2}>
              Iniciar sesión
            </Title>
            <br></br>
            <Form
              name="login-form"
              initialValues={{
                // remember: true,
                email: email,
                password: password,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Usuario"
                name="email"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa tu usuario.",
                  },
                ]}
              >
                <Input
                  maxLength={45}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa tu contraseña.",
                  },
                ]}
              >
                <Input.Password
                  maxLength={20}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item className="txt-center mt-5">
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<LoginOutlined />}
                  loading={loading}
                  disabled={buttonDisabled}
                  size="large"
                >
                  Ingresar
                </Button>
              </Form.Item>
            </Form>
          </div>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              showIcon
              className="custom-alert"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
