import React, { useState, useEffect } from "react";
import { Layout, Menu, List, Avatar, Button, Input, Form, Upload } from "antd";
import { UploadOutlined, SendOutlined } from "@ant-design/icons";
import { auth, storage } from "../../firebase";
import moment from "moment";
import Title from "antd/es/typography/Title";

const { Header, Sider, Content } = Layout;

const Chat = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const fetchConversations = async () => {
      //   const conversationsRef = await getChats(user);
      setConversations([
        {
          avatar:
            "https://robohash.org/laborumesseeaque.png?size=50x50&set=set1",
          name: "Office Assistant IV",
          last_message: "Noninfl dis ova/adnx NOS",
        },
        {
          avatar: "https://robohash.org/autsedab.png?size=50x50&set=set1",
          name: "Staff Accountant III",
          last_message: "Other comp of cystostomy",
        },
        {
          avatar:
            "https://robohash.org/ametrerumtempora.png?size=50x50&set=set1",
          name: "Research Nurse",
          last_message: "Cl skl base fx-prol coma",
        },
        {
          avatar: "https://robohash.org/ettemporaunde.png?size=50x50&set=set1",
          name: "Automation Specialist II",
          last_message: "Fx forearm NOS-closed",
        },
        {
          avatar:
            "https://robohash.org/sedrecusandaecum.png?size=50x50&set=set1",
          name: "Civil Engineer",
          last_message: "Antepart hem NEC-antepar",
        },
        {
          avatar:
            "https://robohash.org/sittemporalabore.png?size=50x50&set=set1",
          name: "Assistant Manager",
          last_message: "Meralgia paresthetica",
        },
        {
          avatar:
            "https://robohash.org/blanditiisestvero.png?size=50x50&set=set1",
          name: "Internal Auditor",
          last_message: "RR acc NEC-employee",
        },
        {
          avatar:
            "https://robohash.org/ipsainventorenesciunt.png?size=50x50&set=set1",
          name: "Geologist IV",
          last_message: "Cord complicat NEC-deliv",
        },
        {
          avatar: "https://robohash.org/quiaestsint.png?size=50x50&set=set1",
          name: "Quality Control Specialist",
          last_message: "Reg enteritis, lg intest",
        },
        {
          avatar:
            "https://robohash.org/providentullamsunt.png?size=50x50&set=set1",
          name: "Assistant Professor",
          last_message: "Tuberculoma brain-unspec",
        },
      ]);
    };
    fetchConversations();
  }, []);

  const handleSendMessage = async () => {
    if (message.trim() === "" && fileList.length === 0) return;

    const newMessage = {
      text: message,
      sender: auth.currentUser.uid,
      createdAt: moment().toISOString(),
      files: [],
    };

    if (fileList.length > 0) {
      const uploadTasks = fileList.map((file) => {
        const fileRef = storage
          .ref()
          .child(`chats/proyecto_coppel/${file.name}`);
        return fileRef
          .put(file.originFileObj)
          .then((snapshot) => fileRef.getDownloadURL());
      });

      const fileUrls = await Promise.all(uploadTasks);
      newMessage.files = fileUrls;
    }

    // await messagesRef.add(newMessage);
    setMessage("");
    setFileList([]);
  };

  const handleBeforeUpload = (file) => {
    setFileList([...fileList, file]);
    return false; // Prevent upload
  };

  useEffect(() => {
    if (selectedConversation) {
      const fetchMessages = async () => {
        setMessages([
          {
            id: "11c4d9e2-529f-4e40-82b4-17b82d1eb849",
            sender: "d473f38f-e3af-4a40-a8dc-b3d22629d334",
            text: "libero convallis eget eleifend luctus ultricies eu nibh quisque id justo sit amet",
            files: [],
          },
          {
            id: "6170f3af-7403-4e74-a1c9-404e50957ad5",
            sender: "83a8a0e2-ea33-4700-b3de-000a7d8163b9",
            text: "maecenas tristique est et tempus semper est quam pharetra magna ac consequat metus sapien ut nunc vestibulum",
            files: [],
          },
          {
            id: "8c88ef9d-029e-4e8a-801a-eb45c9382d9d",
            sender: "6ed3a224-3e5a-461e-94d8-f4ff4d4a98c7",
            text: "eu est congue elementum in hac habitasse platea dictumst morbi",
            files: [],
          },
          {
            id: "e7a8417d-7fbd-4829-ac2f-4342bb4aaefb",
            sender: "a4c6fc79-7233-4ba7-9b25-7be422281104",
            text: "penatibus et magnis dis parturient montes nascetur ridiculus mus etiam",
            files: [],
          },
          {
            id: "4d5bebb1-b9cc-4c99-b20e-c4405a9fb470",
            sender: "09e3c70a-22b7-4bd2-a9c6-b7a53bb1d0ab",
            text: "pede ullamcorper augue a suscipit nulla elit ac nulla sed vel enim sit amet nunc viverra dapibus nulla suscipit",
            files: [],
          },
          {
            id: "d6a02bb8-0e6b-4cd1-acf5-c8615277cbf8",
            sender: "c628ccd0-1539-480c-bf35-2da97a8cc2e6",
            text: "eget elit sodales scelerisque mauris sit amet eros suspendisse accumsan tortor",
            files: [],
          },
          {
            id: "383912ca-1f02-44d7-b595-6fbb94521b04",
            sender: "03e64caf-7cd0-4b92-a1c2-49e48306072f",
            text: "sem praesent id massa id nisl venenatis lacinia aenean sit amet",
            files: [],
          },
          {
            id: "d3e77ff6-7bbe-42c4-a8c2-a2b561ee5e21",
            sender: "78fe23e6-60b7-4b61-a072-b76c69a35b77",
            text: "id pretium iaculis diam erat fermentum justo nec condimentum neque sapien placerat ante nulla justo",
            files: [],
          },
          {
            id: "14de0b4e-2427-45ac-8f5a-7699e363d1df",
            sender: "2f2f6e46-cc29-4f3c-81a6-cf9ed696f9e7",
            text: "amet consectetuer adipiscing elit proin risus praesent lectus vestibulum quam sapien varius ut blandit non interdum in ante vestibulum",
            files: [],
          },
          {
            id: "30531cbd-6c59-4d4c-a5cd-9397e13d2a1a",
            sender: "qZlmpzTDrIWbZXSXVQAWMyg9BSl2",
            text: "justo lacinia eget tincidunt eget tempus vel pede morbi porttitor lorem id ligula suspendisse",
            files: [],
          },
        ]);
      };
      fetchMessages();
    }
  }, [selectedConversation]);

  return (
    <>
    <Title level={3} className="ccg-center">Conversaciones</Title>
      <Layout style={{ maxHeight: "65vh" }}>
        <Layout>
          <Sider width={300} className="chat-layout">
            <List
              itemLayout="horizontal"
              dataSource={conversations}
              renderItem={(conversation) => (
                <List.Item
                  onClick={() => setSelectedConversation(conversation)}
                  style={{ cursor: "pointer" }}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={conversation.avatar} />}
                    title={conversation.name}
                    description={conversation.last_message}
                  />
                </List.Item>
              )}
            />
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 180,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 1, overflowY: "scroll", marginBottom: 16 }}>
                {messages.map((msg) => (
                  <div
                    key={msg.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        msg.sender === auth.currentUser.uid
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    <div
                      style={{
                        background:
                          msg.sender === auth.currentUser.uid
                            ? "#1890ff"
                            : "#f1f1f1",
                        color:
                          msg.sender === auth.currentUser.uid ? "#fff" : "#000",
                        padding: "8px 16px",
                        borderRadius: 16,
                        maxWidth: "60%",
                        margin: "4px 0",
                      }}
                    >
                      {msg.text}
                      {msg.files.map((fileUrl) => (
                        <div key={fileUrl}>
                          <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {fileUrl}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <Form layout="inline" onFinish={handleSendMessage}>
                <Form.Item style={{ flex: 1 }}>
                  <Input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Escribe un mensaje"
                    onDrop={(e) => {
                        e.preventDefault();
                        const files = Array.from(e.dataTransfer.files);
                        files.forEach(file => handleBeforeUpload(file));
                      }}
                    onDragOver={(e) => e.preventDefault()}
    
                  />
                </Form.Item>
                <Form.Item>
                  <Upload
                    fileList={fileList}
                    onChange={({ fileList }) => setFileList(fileList)}
                    beforeUpload={handleBeforeUpload}
                    multiple
                  >
                    <Button icon={<UploadOutlined />} />
                  </Upload>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SendOutlined />}
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Form>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Chat;
