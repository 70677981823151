import React, { useEffect, useState } from "react";
import { Table, Input, Button, Menu, Dropdown, Switch, Col, Row, Spin } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { onSnapshot, collection } from "firebase/firestore";
import { auth, firestore } from "../../firebase";
import { useAuth } from "../Context";
import { useNotification } from "../NotificationContext";
import { actualizarStatus } from "../../servicios/clientes/servicios";

const { Search } = Input;

const BuscarCliente = () => {
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { sucursal, isAdmin } = useAuth();
  const [searchText, setSearchText] = useState("");
  const [uploading, setUploading] = useState(null);
  const openNotificationWithIcon = useNotification();

  useEffect(() => {
    const q = collection(firestore, `sucursal/${sucursal}/cliente`);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clientesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClientes(clientesData);
      setFilteredClientes(clientesData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [sucursal]);

  const menu = (record) => (
    <Menu>
      <Menu.Item onClick={() => handleEditar(record)}>Editar</Menu.Item>
      <Menu.Item onClick={() => handleNuevoAcceso(record)}>Nuevo acceso</Menu.Item>
      {isAdmin && 
      <Menu.Item onClick={() => handleVerAccesos(record)}>Ver accesos</Menu.Item>
      }
    </Menu>
  );

  const columns = [
    {
      title: "Razón Social",
      dataIndex: "razon_social",
      key: "razon_social"
    },
    {
      title: "RFC",
      dataIndex: "rfc",
      key: "rfc",
    },
    {
      title: "Celular",
      dataIndex: "celular",
      key: "celular",
    },
    {
      title: "Contacto",
      dataIndex: "contacto",
      key: "contacto",
    },
    {
      title: "Status",
      dataIndex: "estatus",
      key: "estatus",
      render: (text, record) => {
        const status = record.estatus === "Activo" ? true : false;
        return <>
        {uploading === record.rfc ? <Spin></Spin> : 
        <>
          <Switch
          checked={status}
          onChange={(checked) => handleStatusChange(record, checked)}
          />
        </>
        }
        </>
      }
    },
    {
      title: "Fecha de Creación",
      dataIndex: "creacion",
      key: "creacion",
    },
    {
      title: "Opciones",
      key: "opciones",
      render: (text, record) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleStatusChange = async (record, checked) => {
    setUploading(record.rfc);
    console.log('actalizando status: ', record);
    const body = {
      cliente: record.id,
      status: checked ? "Activo" : "Inactivo",
      sucursal,
    };
    
    if (record.area && record.area.length) body['areas']= [record.area]
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await actualizarStatus(token, body);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Éxito",
          `Se ${body.estatus} cliente ${record.razon_social}.`
          );
        } else {
          openNotificationWithIcon(
            "warning",
            "Algo salió mal",
            response.error.msg ? response.error.msg : response.error
            );
          }
        } catch (error) {
          openNotificationWithIcon(
            "error",
            "Algo salió mal",
            `El cliente ${record.razon_social} no se ${body.estatus.toLowerCase()}.`
            );
            console.error("Error: ", error);
          }
          setUploading(null);
        };
        
        const handleVerAccesos = (cliente) => {
          console.log('ver accesos: ', cliente);
        };
        
        const handleNuevoAcceso = (cliente) => {
          console.log('nuevo acceso: ', cliente);
          
        };
        
        const handleEditar = (cliente) => {
          console.log('editar: ', cliente);
  };

  const filteredData = clientes.filter(
    (item) =>
      item.razon_social.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
    <Row>
      <Col xs={24} md={8}>
        <Search
          placeholder="Buscar por razón social"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: 16 }}
        />
      </Col>
    </Row>
      <Table
        rowKey="rfc"
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        pagination
        locale={{
          emptyText: "No hay clientes",
          filterConfirm: "Aceptar",
          filterReset: "Reiniciar",
          selectAll: "Seleccionar todo",
          selectInvert: "Invertir selección",
          sortTitle: "Ordenar",
          filterTitle: "Filtro",
          filterEmptyText: "No hay filtros",
          triggerDesc: "Ordenar descendente",
          triggerAsc: "Ordenar ascendente",
          cancelSort: "Cancelar",
        }}
      />
    </>
  );
};

export default BuscarCliente;
