import { Form, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { obtenerPuestos } from "../../servicios/puestos/servicios";

const Puestos = ({ sucursal, onPuestoChange }) => {
  const { Option } = Select;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlePuestoChange = (value) => {
    const selectedPuesto = data.find((puesto) => puesto.nombre === value);
    const areas = selectedPuesto ? selectedPuesto.areas : [];
    const nombrePuesto = selectedPuesto ? selectedPuesto.nombre : '';

    onPuestoChange(nombrePuesto, areas);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const tokenValid = await auth.currentUser.getIdToken(true);
        const body = { sucursal };
        const response = await obtenerPuestos(tokenValid, body);
        setData(response.success ? response.info.sort((a, b) => a.nombre.localeCompare(b.nombre)) : []);
      } catch (err) {
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    if (sucursal) {
      loadData();
    }
  }, [sucursal]);

  if (loading) {
    return <Spin tip="Cargando puestos..." />;
  }

  return (
    <Form.Item
      label="Puesto"
      name="puesto"
      rules={[
        {
          required: true,
          message: "Por favor selecciona un puesto",
        },
      ]}
    >
      <Select defaultValue={"0"} onChange={handlePuestoChange}>
        <Option value="0" disabled>
          Selecciona un puesto
        </Option>
        {data.map((item) => (
          <Option key={item.nombre} value={item.nombre}>
            {item.nombre}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Puestos;
