import {
  Button,
  Form,
  Select,
  Input,
  notification,
  Row,
  Col,
  DatePicker,
  Spin,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { Suspense, useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { auth } from "../../firebase";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/es_ES";
import "dayjs/locale/es";
import { saveUser } from "../../servicios/empleados/servicios";
import Puestos from "../puestos/Puestos";
import { LoadingOutlined } from "@ant-design/icons";
import Sucursales from "../sucursales/Sucursales";
import { useNotification } from "../NotificationContext";

dayjs.locale("es");

const AgregarEmpleado = () => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [customGender, setCustomGender] = useState("");
  const [puesto, setPuesto] = useState(null);
  const [areas, setAreas] = useState([]);
  const [sucursal, setSucursal] = useState(null);
  const [additionalValues, setAdditionalValues] = useState([]);

  const [form] = Form.useForm();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const Loader = <Spin indicator={antIcon} />;

  const openNotificationWithIcon = useNotification();

  const formFinish = async (values) => {
    setLoading(true);
    try {
      const tokenValid = await auth.currentUser.getIdToken(true);
      const response = await saveUser(tokenValid, values);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Empleado agregado",
          `El usuario ${values.nombre} se guardo exitosamente.`
        );
        form.resetFields();
      } else {
        openNotificationWithIcon(
          "warning",
          "Empleado no guardado",
          response.error.msg ? response.error.msg : response.error
        );
        showErrorMessages(response.errors);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Empleado no guardado",
        `El usuario ${values.nombre} no se guardo exitosamente.`
      );
      console.error("Error: ", error);
    }
    setLoading(false);
  };

  const handleGenderChange = (value) => {
    setGender(value);
    if (value !== "Otro") {
      setCustomGender("");
    }
  };

  const handleCustomGenderChange = (e) => {
    setCustomGender(e.target.value);
  };

  const puestoChange = (value, areas) => {
    setPuesto(value);
    setAreas(areas);
    setAdditionalValues([]);
  };

  const sucursalChange = async (value) => {
    setSucursal(value);
  }

  const showErrorMessages = (errorObject) => {
    if (!errorObject || !Array.isArray(errorObject)) {
        console.error('Invalid error object structure');
        return;
    }

    errorObject.forEach((error) => {
        const field = document.getElementById(error.field);
        if (field) {
            const errorMessage = document.createElement('div');
            errorMessage.className = 'error-message'; // Puedes agregar estilos CSS para esta clase
            errorMessage.innerText = error.error;
            
            // Limpiar errores anteriores si existen
            const existingError = field.nextSibling;
            if (existingError && existingError.className === 'error-message') {
                existingError.remove();
            }

            field.parentNode.insertBefore(errorMessage, field.nextSibling);
        } else {
            console.warn(`Field with id ${error.field} not found`);
        }
    });
};

  return (
    <>
      <Title level={3} className="ccg-center">
        Agregar Empleado
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={formFinish}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="nombre"
              label="Nombre(s)"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su(s) nombre(s)",
                },
              ]}
            >
              <Input placeholder="Ingrese su(s) nombre(s)" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="ap_paterno"
              label="Apellido Paterno"
              name="ap_paterno"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su apellido paterno",
                },
              ]}
            >
              <Input placeholder="Ingrese su apellido paterno" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="ap_materno"
              label="Apellido Materno"
              name="ap_materno"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su apellido materno",
                },
              ]}
            >
              <Input placeholder="Ingrese su apellido materno" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="domicilio"
              label="Domicilio"
              name="domicilio"
              rules={[
                {
                  required: false,
                  message: "Por favor ingrese su domicilio",
                }
              ]}
            >
              <Input placeholder="Ingrese su domicilio del empleado" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="fecha_nacimiento"
              label="Fecha de Nacimiento"
              name="fecha_nacimiento"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione su fecha de nacimiento",
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                locale={locale}
                style={{ width: "100%" }}
                placeholder="Seleccione su fecha de nacimiento"
                picker="date"
              />
            </Form.Item>
          </Col>
          
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="fecha_ingreso"
              label="Fecha de Ingreso"
              name="fecha_ingreso"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione su fecha de ingreso",
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                locale={locale}
                style={{ width: "100%" }}
                placeholder="Seleccione su fecha de ingreso"
                picker="date"
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="celular"
              label="Celular"
              name="celular"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su número celular",
                },
                {
                  pattern: /^[0-9]{12}$/,
                  message: "Ingrese un número de teléfono válido (10 dígitos)",
                },
              ]}
            >
              <PhoneInput
                className="number"
                country={"mx"}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="genero"
              label="Género"
              name="genero"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione su género",
                },
              ]}
            >
              <Select
                value={gender}
                onChange={handleGenderChange}
                placeholder="Seleccione su género"
              >
                <Option value="Hombre">Hombre</Option>
                <Option value="Mujer">Mujer</Option>
                <Option value="Otro">Otro</Option>
              </Select>
            </Form.Item>
          </Col>
          {gender === "Otro" && (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                id="otro_genero"
                label="Especifique su género"
                name="otro_genero"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su género",
                  },
                ]}
              >
                <Input
                  value={customGender}
                  onChange={handleCustomGenderChange}
                  placeholder="Ingrese su género"
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="correo"
              label="Correo"
              name="correo"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el correo",
                },
                {
                  type: "email",
                  message: "Por favor ingresa un correo electrónico válido",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Ingrese un correo del empleado"
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="password"
              label="Contraseña"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa la contraseña",
                },
                {
                  min: 8,
                  message: "La contraseña debe tener al menos 8 caracteres",
                },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!¡%*?&#¿])[A-Za-z\d@$!¡%*?&#¿]{8,}$/,
                  message:
                    "La contraseña debe incluir al menos una mayúscula, una minúscula, un número y un carácter especial (@$!¡%*?&#¿)",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="confirmar_password"
              label="Confirmar Contraseña"
              name="confirmar_password"
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa la contraseña",
                },
                {
                  min: 8,
                  message: "La contraseña debe tener al menos 8 caracteres",
                },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#¿])[A-Za-z\d@$!%*?&#¿]{8,}$/,
                  message:
                    "La contraseña debe incluir al menos una mayúscula, una minúscula, un número y un carácter especial (@$!%*?&#¿)",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Suspense fallback={Loader}>
              <Sucursales onSucursalChange={sucursalChange} />
            </Suspense>
          </Col>

          {sucursal && (
            <>
              <Col xs={24} md={12} lg={8}>
                <Puestos onPuestoChange={puestoChange} sucursal={sucursal} />
              </Col>
            </>
          )}

          {puesto && areas.length > 0 && (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                id="areas"
                label="Área de trabajo"
                name="areas"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione las áreas de trabajo",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  value={additionalValues}
                  onChange={setAdditionalValues}
                  placeholder="Seleccione las áreas"
                >
                  {areas.map((area) => (
                    <Option key={area.nombre} value={area.nombre}>
                      {area.nombre}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} md={24} lg={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} size="large">
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AgregarEmpleado;
