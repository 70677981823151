import React, { useState } from 'react';
import { Layout, Menu, Select, Card, Row, Col } from 'antd';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

const { Header, Content } = Layout;
const { Option } = Select;

const branches = ['Sucursal 1', 'Sucursal 2', 'Sucursal 3']; // Lista de sucursales
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const workersData = [
  { name: 'Activos', value: 400 },
  { name: 'Inactivos', value: 300 }
];

const quotationsData = [
  { name: 'Aceptadas', value: 2400 },
  { name: 'Rechazadas', value: 4567 },
  { name: 'Cerradas', value: 1398 },
];

const customersData = [
  { name: 'Activos', value: 3000 },
  { name: 'Inactivos', value: 500 },
];

const projectsData = [
  { name: 'Activos', value: 200 },
  { name: 'Terminados', value: 150 },
  { name: 'Pendientes', value: 50 },
];

const vehiclesData = [
  { name: 'Vehículos', value: 50 },
];

const equipmentData = [
  { name: 'Equipos de trabajo', value: 100 },
];

const Dashboard = () => {
  const [selectedBranch, setSelectedBranch] = useState(branches[0]);

  const handleBranchChange = (value) => {
    setSelectedBranch(value);
    // Aquí podrías actualizar los datos según la sucursal seleccionada
  };

  const renderPieChart = (data, title) => (
    <Card title={title} style={{ marginBottom: 24, textAlign: 'center' }}>
      <PieChart width={400} height={300}>
        <Pie
          data={data}
          cx={200}
          cy={150}
          labelLine={true}
          label={({ name, value }) => `${name}: ${value}`}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </Card>
  );

  const renderBarChart = (data, title) => (
    <Card title={title} style={{ marginBottom: 24 }}>
      <BarChart
        width={400}
        height={300}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </Card>
  );

  return (
      <>
        <div>
          <Select
            value={selectedBranch}
            onChange={handleBranchChange}
            style={{ width: 200, marginBottom: 24 }}
            >
            {branches.map(branch => (
                <Option key={branch} value={branch}>{branch}</Option>
                ))}
          </Select>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={12}>
              {renderPieChart(workersData, 'Trabajadores por Estado')}
            </Col>
            <Col xs={24} sm={12} lg={12}>
              {renderPieChart(quotationsData, 'Cotizaciones por Estado')}
            </Col>
            <Col xs={24} sm={12} lg={12}>
              {renderPieChart(customersData, 'Clientes por Estado')}
            </Col>
            <Col xs={24} sm={12} lg={12}>
              {renderPieChart(projectsData, 'Proyectos por Estado')}
            </Col>
            <Col xs={24} sm={12} lg={12}>
              {renderBarChart(vehiclesData, 'Cantidad de Vehículos')}
            </Col>
            <Col xs={24} sm={12} lg={12}>
              {renderBarChart(equipmentData, 'Cantidad de Equipos de Trabajo')}
            </Col>
          </Row>
        </div>
    </>
  );
};

export default Dashboard;
