import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card, Space } from "antd";
import { storage } from "../../firebase";
import { FilePdfOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import Title from "antd/es/typography/Title";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

const VerCapacitacion = ({ documento }) => {
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState("");
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const videoRef = useRef(null);


  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleModalClose = () => {
    // Detener la reproducción del video al cerrar el modal
    if (videoRef.current) {
      videoRef.current.pause();
    }
    // Lógica adicional al cerrar el modal si es necesaria
    handleClose();
  };

  const getFile = async (path) => {
    try {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`/capacitaciones/${path}`);
      const url = await fileRef.getDownloadURL();
      setUrl(url);
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        console.error("Error: El archivo no existe en la ruta especificada.");
      } else if (error.code === "storage/unauthorized") {
        console.error("Error: No tienes permisos para acceder a este archivo.");
      } else if (error.code === "storage/canceled") {
        console.error("Error: La operación fue cancelada.");
      } else {
        console.error("Error desconocido al obtener el archivo:", error);
      }
    }
  };

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getFile(documento.documento);
  }, [documento]);

  return (
    <>
      <Card
        hoverable
        cover={
          documento.documento.includes(".mp4") ? (
            <video
              controls
              volume="1.0"
              controlsList="nodownload"
              onContextMenu={disableRightClick}
              style={{ width: "100%", height: "100px", objectFit: "cover" }}
              >
              <source src={url} type="video/mp4"></source>
            </video>
          ) : (
            <div
              style={{
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FilePdfOutlined style={{ fontSize: "48px", color: "#d32f2f" }} />
            </div>
          )
        }
      >
        <Card.Meta
          title={documento.nombre}
          description={`Versión: ${documento.version}`}
        />
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <Button
            type="primary"
            icon={
              documento.documento.includes(".pdf") ? (
                <FilePdfOutlined />
              ) : (
                <PlayCircleOutlined />
              )
            }
            onClick={handleOpen}
          >
            {documento.documento.includes(".pdf")
              ? "Ver Documento"
              : "Reproducir Video"}
          </Button>
        </div>
      </Card>

      <Modal
        visible={visible}
        title={documento.nombre}
        footer={null}
        onCancel={handleModalClose}
        width={documento.documento.includes(".pdf") ? "90%" : "80%"}
      >
        {documento.documento.includes(".pdf") ? (
          <>
          <Title level={3} className="ccg-center">
            Visualilzador de Documentos
          </Title>
          <Space>
            <Toolbar>
              {(props) => {
                const {
                  ZoomIn,
                  ZoomOut,
                } = props;

                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      + Zoom <ZoomOut />
                      - Zoom <ZoomIn />
                    </div>
                  </>
                );
              }}
            </Toolbar>
          </Space>
          <Worker
            workerUrl={
              "https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js"
            }
          >
            <Viewer
              fileUrl={url}
              plugins={[toolbarPluginInstance]}
            />
          </Worker>
        </>
        ) : (
          <video ref={videoRef} width="100%" src={url} title={documento.nombre} controls volume="0" controlsList="nodownload" onContextMenu={disableRightClick} autoPlay
          />
        )}
      </Modal>
    </>
  );
};

export default VerCapacitacion;
