import React from 'react';
import { Alert, Card } from 'antd';

const NoPermisos = () => {
  return (
    <Card 
      style={{ 
        maxWidth: '600px', 
        margin: '50px auto', 
        padding: '20px', 
        textAlign: 'center',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Alert
        message="Acceso Restringido"
        description="No cuentas con los permisos necesarios, consulta con tu supervisor o administrador."
        type="warning"
        showIcon
        style={{
          fontSize: '16px',
        }}
      />
    </Card>
  );
};

export default NoPermisos;
