import config from '../config';

const apiUrl = config.apiUrl;

export const saveUser = (token, values) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...values, fecha_nacimiento: values.fecha_nacimiento.format("YYYY-MM-DD"), fecha_ingreso: values.fecha_ingreso.format("YYYY-MM-DD") }),
    };

    fetch(
      apiUrl+"/empleado/crear",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(false);
      });
  });
};

export const getAll = (token) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(
      apiUrl+"/empleado/obtener-todos",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al obtener usuarios: ", err);
        reject(false);
      });
  });
};

export const getSellers = (token, myself, uid) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ myself: myself, uid: uid })
    };

    fetch(
      apiUrl+"/empleado/get-sellers",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al obtener usuarios: ", err);
        reject(false);
      });
  });
};

export const getByFilter = (token) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(
      apiUrl+"/empleado/obtener",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al obtener usuarios con almacen: ", err);
        reject(false);
      });
  });
};

export const actualizarEstatus = (token, values) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({...values})
    };

    fetch(
      apiUrl+"/empleado/actualizar-status",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al desabilitar usuario: ", err);
        reject(false);
      });
  });
};
