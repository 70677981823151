import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAZBWb5xmt4x5v6F65QTfV8CKlnd3uUcGA",
  authDomain: "control-de-calidad-y-geotecnia.firebaseapp.com",
  databaseURL: "https://control-de-calidad-y-geotecnia-default-rtdb.firebaseio.com",
  projectId: "control-de-calidad-y-geotecnia",
  storageBucket: "control-de-calidad-y-geotecnia.appspot.com",
  messagingSenderId: "482640598101",
  appId: "1:482640598101:web:d01b1c1565b58b88b9f0dc",
  measurementId: "G-25MFXZRQQJ",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCrWA1VCAxSAQaSBdO4ahwt8dJNBEUyzwE",
//   authDomain: "ccg-test-9f04e.firebaseapp.com",
//   projectId: "ccg-test-9f04e",
//   storageBucket: "ccg-test-9f04e.appspot.com",
//   messagingSenderId: "578638607466",
//   appId: "1:578638607466:web:e722db20bd630f8ffcc613"
// };

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const storage = app.storage();
const firestore = app.firestore();


export { auth, storage, firestore };
