import { Form, Select } from "antd";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { useAuth } from "../Context";

const Empleados = ({ onSucursalChange, search = false }) => {
  const { Option } = Select;
  const [data, setData] = useState([]);
  const { sucursal } = useAuth();

  useEffect(() => {
    const q = collection(firestore, `sucursal/${sucursal}/empleado`);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clientesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(clientesData.filter((item) => item.estatus === "Activo"));
    });

    return () => unsubscribe();
  }, [sucursal]);

  return (
    <Form.Item
      label="Empleado"
      name="empleado"
      rules={[
        {
          required: true,
          message: "Por favor selecciona un empleado",
        },
      ]}
    >
      <Select
        defaultValue={"0"}
        onChange={onSucursalChange}
        showSearch={search}
        filterOption={(input, option) => {
          const fullName = `${option.children}`.toLowerCase();
          return fullName.includes(input.toLowerCase());
        }}
      >
        <Option value="0" disabled>
          Selecciona un empleado
        </Option>
        {data.map((item) => (
          <Option key={item.id}>
            {item.nombre + " " + item.ap_paterno + " " + item.ap_materno}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Empleados;
