import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
} from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  PlusOutlined,
  LogoutOutlined,
  SearchOutlined,
  UserOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  BookOutlined,
  BellOutlined,
  FolderOutlined,
  MessageOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  CarOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import { auth, firestore } from "../firebase";
import { useAuth } from "./Context";
import { ReactComponent as BranchIcon } from "../img/icons/branch.svg";
import { ReactComponent as EmpleyeeIcon } from "../img/icons/empleado.svg";
import { ReactComponent as HolidayIcon } from "../img/icons/holiday.svg";
import { ReactComponent as RHIcon } from "../img/icons/rh.svg";
import { ReactComponent as ConcretoIcon } from "../img/icons/concreto.svg";
import { ReactComponent as GasStationIcon } from "../img/icons/gas-station.svg";
import { ReactComponent as PositionIcon } from "../img/icons/position.svg";
import { ReactComponent as PermissionIcon } from "../img/icons/permission.svg";
import { ReactComponent as MetalIcon } from "../img/icons/metal.svg";
import { NotificationProvider } from "./NotificationContext";
import NoPermisos from "./NoPermisos";

const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = () => {
  const [employeeData, setEmployeeData] = useState({ puesto: null, areas: [] });
  const [collapsed, setCollapsed] = useState(false);
  const {
    currentUser,
    role,
    sucursal,
    permisos,
    setPermisos,
    setIsAdmin,
    isAdmin,
  } = useAuth();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isBirthday, setIsBirthday] = useState(false);

  const handleMenuClick = () => {
    setVisible(!visible);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      message.success("Sesión cerrada con exito.");
      navigate("/");
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
    }
  };

  const handleDelete = (key) => {
    console.log(`Eliminar notificación con key: ${key}`);
  };

  const notifications = [
    {
      key: 1,
      title: "Nuevo cotización asignada",
      description: "Esta es la descripción de la notificación 1.",
    },
    {
      key: 2,
      title: "Proyecto CCG0102",
      description: "Cotización pendiente.",
    },
  ];

  const notificationMenu = (
    <Menu className="notifications-content">
      {notifications.map((notification, index) => (
        <Menu.Item key={notification.key}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <strong>{notification.title}</strong>
              <p>{notification.description}</p>
            </div>
            <Button
              danger
              type="text"
              onClick={() => handleDelete(notification.key)}
              icon={<DeleteOutlined />}
            ></Button>
          </div>
          {index < notifications.length - 1 && <hr />}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    if (!currentUser) return;

    setIsAdmin(
      role === "Director General" || role === "Gerente General" ? true : false
    );
    const unsubscribe = firestore
      .collection("sucursal")
      .doc(sucursal)
      .collection("empleado")
      .doc(currentUser.uid)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setEmployeeData({
              puesto: data.puesto || "",
              areas: data.areas || [],
            });
          } else {
            console.log("No tiene puesto asignado el empleado!");
          }
        },
        (error) => {
          console.log("Error getting document:", error);
        }
      );

    return () => unsubscribe();
  }, [currentUser, role, setIsAdmin, sucursal]);

  useEffect(() => {
    if (!employeeData.puesto && employeeData.areas.length === 0) {
      return;
    }

    const permisosTemp = [];

    const fetchPermisos = async () => {
      try {
        console.log("consultando permisos: ", employeeData);

        if (!employeeData.areas || employeeData.areas.length === 0) {
          console.log('buscando permisos de puesto...')
          const puestoSnapshot = await firestore
          .collection("sucursal")
            .doc(sucursal)
            .collection("puesto")
            .doc(employeeData.puesto)
            .collection("configuracion")
            .doc("permisos")
            .get();
            
            if (puestoSnapshot.exists) {
              permisosTemp.push({ ...puestoSnapshot.data() });
            }
          } else {
          console.log('buscando permisos de areas...')
          for (const area of employeeData.areas) {
            const areaSnapshot = await firestore
              .collection("sucursal")
              .doc(sucursal)
              .collection("puesto")
              .doc(employeeData.puesto)
              .collection("areas")
              .doc(area)
              .collection("configuracion")
              .doc("permisos")
              .get();

            permisosTemp.push(areaSnapshot.data());
          }
        }

        console.log("permisos: ", permisosTemp[0]);
        setPermisos(permisosTemp[0]);
      } catch (error) {
        console.log("Error fetching permisos:", error);
      }
    };

    if (!isAdmin) fetchPermisos();
  }, [employeeData.puesto, employeeData.areas, sucursal, employeeData, isAdmin, setPermisos]);

  return (
    <NotificationProvider>
      <Layout style={{ minHeight: "100vh", background: "#283891" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          style={{
            background: "#fff",
            marginTop: "64px",
            maxHeight: "86vh",
            overflowX: "scroll",
          }}
        >
          <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline" >
            {(isAdmin || permisos.bienvenida) && (
              <Menu.Item key="bienvenida" icon={<HomeOutlined />}>
                <Link to="/sistema/bienvenida">Inicio</Link>
              </Menu.Item>
            )}
            {(isAdmin || permisos.perfil) && (
              <Menu.Item key="perfil" icon={<UserOutlined />}>
                <Link to="/sistema/perfil">Perfil</Link>
              </Menu.Item>
            )}
            {(isAdmin || permisos.sucursales) && (
              <Menu.Item key="sucursal" icon={<BranchIcon />}>
                <Link to="/sistema/sucursal">Sucursales</Link>
              </Menu.Item>
            )}
            {(isAdmin || permisos.empleado) && (
              <Menu.SubMenu
                key="empleados"
                icon={<EmpleyeeIcon />}
                title="Empleados"
              >
                {(isAdmin || permisos.empleado.buscar) && (
                  <Menu.Item key="buscarEmpleado" icon={<SearchOutlined />}>
                    <Link to="/sistema/empleado/buscar">Buscar</Link>
                  </Menu.Item>
                )}
                {(isAdmin || permisos.empleado.agregar) && (
                  <Menu.Item key="agregarEmpleado" icon={<PlusOutlined />}>
                    <Link to="/sistema/empleado/agregar">Agregar</Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
            {(isAdmin || permisos.cliente) && (
              <Menu.SubMenu
                key="clilentes"
                icon={<UserSwitchOutlined />}
                title="Clientes"
              >
                {(isAdmin || permisos.cliente.buscar) && (
                  <Menu.Item key="buscarCliente" icon={<SearchOutlined />}>
                    <Link to="/sistema/cliente/buscar">Buscar</Link>
                  </Menu.Item>
                )}
                {(isAdmin || permisos.cliente.agregar) && (
                  <Menu.Item key="agregarCliente" icon={<PlusOutlined />}>
                    <Link to="/sistema/cliente/agregar">Agregar</Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
            {(isAdmin || permisos.proyecto) && (
              <Menu.SubMenu
                key="proyecto"
                icon={<FolderOutlined />}
                title="Proyectos"
              >
                {(isAdmin || permisos.proyecto.concreto) && (
                  <Menu.SubMenu
                    key="concreto"
                    icon={<ConcretoIcon />}
                    title="Concreto"
                  >
                    {(isAdmin || permisos.proyecto.concreto.buscar) && (
                      <Menu.Item
                        key="buscarProyectoConcreto"
                        icon={<SearchOutlined />}
                      >
                        <Link to="/sistema/proyectos/concreto/buscar">
                          Buscar
                        </Link>
                      </Menu.Item>
                    )}
                    {(isAdmin || permisos.proyecto.concreto.agregar) && (
                      <Menu.Item
                        key="agregarProyectoConcreto"
                        icon={<PlusOutlined />}
                      >
                        <Link to="/sistema/proyectos/concreto/agregar">
                          Agregar
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {(isAdmin || permisos.proyecto.estructura) && (
                  <Menu.SubMenu
                    key="estructura"
                    icon={<MetalIcon />}
                    title="Estructura"
                  >
                    {(isAdmin || permisos.proyecto.estructura.buscar) && (
                      <Menu.Item
                        key="buscarProyectoEstructura"
                        icon={<SearchOutlined />}
                      >
                        <Link to="/sistema/proyectos/estructura/buscar">
                          Buscar
                        </Link>
                      </Menu.Item>
                    )}
                    {(isAdmin || permisos.proyecto.estructura.agregar) && (
                      <Menu.Item
                        key="agregarProyectoEstructura"
                        icon={<PlusOutlined />}
                      >
                        <Link to="/sistema/proyectos/estructura/agregar">
                          Agregar
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {(isAdmin || permisos.proyecto.agregar) && (
                  <Menu.Item
                    key="agregarProyectoAregar"
                    icon={<PlusOutlined />}
                  >
                    <Link to="/sistema/proyectos/agregar">
                      Agregar
                    </Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
            {(isAdmin || permisos.conversaciones) && (
              <Menu.Item key="conversacion" icon={<MessageOutlined />}>
                <Link to="/sistema/chat">Conversaciones</Link>
              </Menu.Item>
            )}
            {(isAdmin || permisos.capacitacion) && (
              <Menu.SubMenu
                key="capacitacion"
                icon={<BookOutlined />}
                title="Biblioteca"
              >
                {(isAdmin || permisos.capacitacion.normas) && (
                  <Menu.Item key="buscarNorma" icon={<SearchOutlined />}>
                    <Link to="/sistema/capacitacion/norma/buscar">Normas</Link>
                  </Menu.Item>
                )}
                {(isAdmin || permisos.capacitacion.procedimientos) && (
                  <Menu.Item
                    key="buscarProcedimiento"
                    icon={<SearchOutlined />}
                  >
                    <Link to="/sistema/capacitacion/procedimiento/buscar">
                      Procedimientos
                    </Link>
                  </Menu.Item>
                )}
                {(isAdmin || permisos.capacitacion.buscar) && (
                  <Menu.Item key="buscarBibliotecas" icon={<SearchOutlined />}>
                    <Link to="/sistema/capacitacion/buscar">Buscar</Link>
                  </Menu.Item>
                )}
                {(isAdmin || permisos.capacitacion.agregar) && (
                  <Menu.Item key="agregarCapacitacion" icon={<PlusOutlined />}>
                    <Link to="/sistema/capacitacion/agregar">Agregar</Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}

            {(isAdmin || permisos.solicitudes) && (
              <Menu.Item key="solicitudes" icon={<IssuesCloseOutlined />}>
                <Link to="/sistema/solicitud">Solicitudes</Link>
              </Menu.Item>
            )}
            {(isAdmin || permisos.vehiculos) && (
              <Menu.SubMenu key="cars" icon={<CarOutlined />} title="Vehiculos">
                {(isAdmin || permisos.vehiculos.buscar) && (
                  <Menu.Item key="buscarCarro" icon={<SearchOutlined />}>
                    <Link to="/sistema/vehiculo/buscar">Buscar</Link>
                  </Menu.Item>
                )}
                {(isAdmin || permisos.vehiculos.agregar) && (
                  <Menu.Item key="agregarCarro" icon={<PlusOutlined />}>
                    <Link to="/sistema/vehiculo/agregar">Agregar</Link>
                  </Menu.Item>
                )}
                {(isAdmin || permisos.vehiculos.carga) && (
                  <Menu.Item
                    key="agregarCargaGasolina"
                    icon={<GasStationIcon />}
                  >
                    <Link to="/sistema/vehiculo/carga-gas">
                      Registrar Carga
                    </Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
            {(isAdmin || permisos.recursos_humanos) && (
              <Menu.SubMenu
                key="recursosHumanos"
                icon={<RHIcon />}
                title="Rec. Humanos"
              >
                {(isAdmin || permisos.recursos_humanos.solicitudes) && (
                  <Menu.SubMenu
                  key="solicitudRH"
                  icon={<HolidayIcon />}
                  title="Solicitudes"
                  >
                  {(isAdmin || permisos.recursos_humanos.solicitudes.buscar) && (
                  <Menu.Item key="buscarSolicitudRH" icon={<SearchOutlined />}>
                    <Link to="/sistema/solicitud/buscar">Buscar</Link>
                  </Menu.Item>
                  )}
                  {(isAdmin || permisos.recursos_humanos.solicitudes.agregar) && (
                  <Menu.Item key="agregarSolicitudRH" icon={<PlusOutlined />}>
                    <Link to="/sistema/solicitud/agregar">Agregar</Link>
                  </Menu.Item>
                  )}
                </Menu.SubMenu>
                )}
                {(isAdmin || permisos.recursos_humanos.asistencias) && (
                  <Menu.SubMenu
                  key="asistencias"
                  icon={<ClockCircleOutlined />}
                  title="Asistencias"
                  >
                    {(isAdmin || permisos.recursos_humanos.asistencias.buscar) && (
                  <Menu.Item key="buscarAsistencia" icon={<SearchOutlined />}>
                    <Link to="/sistema/asistencia/buscar">Buscar</Link>
                  </Menu.Item>
                )}
                    {(isAdmin || permisos.recursos_humanos.asistencias.agregar) && (
                  <Menu.Item key="agregarAsistencia" icon={<PlusOutlined />}>
                    <Link to="/sistema/asistencia/agregar">Agregar</Link>
                  </Menu.Item>
                )}
                </Menu.SubMenu>
                )}
              </Menu.SubMenu>
            )}
            {(isAdmin || permisos.configuracion) && (
              <>
                <Menu.SubMenu
                  key="configuracion"
                  icon={<SettingOutlined />}
                  title="Configuración"
                >
                  <Menu.Item key="configurarPuestos" icon={<PositionIcon />}>
                    <Link to="/sistema/configurar/puestos">Puestos</Link>
                  </Menu.Item>
                  <Menu.Item key="configurarClientes" icon={<PermissionIcon />}>
                    <Link to="/sistema/configurar/clientes">Clientes</Link>
                  </Menu.Item>
                </Menu.SubMenu>
              </>
            )}
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              <Link onClick={handleLogout}>Salir</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            className="ccg-header"
            style={{
              padding: 0,
              paddingTop: "25px",
              paddingRight: "40px",
              color: "white",
              backgroundColor: "#283891",
            }}
          >
            <Dropdown
              overlay={notificationMenu}
              trigger={["click"]}
              onOpenChange={setVisible}
              open={visible}
            >
              <Badge count={notifications.length} style={{ color: "white" }}>
                <Button
                  style={{ color: "white" }}
                  type="text"
                  icon={<BellOutlined />}
                  size="middle"
                  onClick={handleMenuClick}
                />
              </Badge>
            </Dropdown>
          </Header>
          {isAdmin || permisos ? (
            <>
              <Content
                style={{
                  margin: "0",
                  maxHeight: "85vh",
                  overflowY: "scroll",
                  padding: "0px 25px",
                }}
              >
                <Breadcrumb className="ccg-colors">
                  {/* <div> */}
                  <Breadcrumb.Item>Bienvenido</Breadcrumb.Item>
                  <Breadcrumb.Item>{role}</Breadcrumb.Item>
                  <Breadcrumb.Item>{currentUser.displayName}</Breadcrumb.Item>
                  {/* </div> */}
                  {/* <Breadcrumb.Item>
                Sesión caduca a las: {expirationDate}
              </Breadcrumb.Item> */}
                  {/* {role === "Vendedor Local" ? (
              // <div >
              <Breadcrumb.Item>
                <Button style={{ marginRight: "200px" }}>
                  <Link to="/transferencia/agregar">Realizar corte</Link>
                </Button>
              </Breadcrumb.Item>
            ) : (
              // </div>
              <></>
            )} */}
                </Breadcrumb>
                {isBirthday && (
                  <Alert
                    message={`¡Feliz cumpleaños, ${currentUser.displayName}!`}
                    description="Esperamos que tengas un día maravilloso. Gracias por ser parte de CCG S.A de C.V."
                    type="success"
                    showIcon
                  />
                )}
                <Outlet />
              </Content>
            </>
          ) : (
            <>
              <NoPermisos />
            </>
          )}
          <Footer className="ccg-footer">
            CONTROL DE CALIDAD Y GEOTÉCNIA S.A DE C.V
          </Footer>
        </Layout>
      </Layout>
    </NotificationProvider>
  );
};

export default AdminLayout;
