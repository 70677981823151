import React, { Suspense, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Steps,
  message,
  Divider,
  Row,
  Col,
  Spin,
} from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { auth, storage } from "../../firebase";
import { useAuth } from "../Context";
import Sucursales from "../sucursales/Sucursales";
import Puestos from "../puestos/Puestos";
import { guardarCapacitacion } from "../../servicios/capacitaciones/servicios";
import { useNotification } from "../NotificationContext";

const { Step } = Steps;
const { Option } = Select;

const AgregarCapacitacion = () => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [areas, setAreas] = useState([]);
  const [sucursal, setSucursal] = useState(null);
  const [puesto, setPuesto] = useState(null);
  const [additionalValues, setAdditionalValues] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const openNotificationWithIcon = useNotification();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const Loader = <Spin indicator={antIcon} />;

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData({ ...formData, ...values });
        setCurrent(current + 1);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const handleFinish = async () => {
    setLoading(true);
    try {
      const file = formData.pdf.file;
      console.log('file: ', formData.pdf);
      const isPdf = file.type === "application/pdf";
      const isMp4 = file.type === "video/mp4";
      let extension = "";

      if (isPdf) {
        extension = ".pdf";
      } else if (isMp4) {
        extension = ".mp4";
      } else {
        openNotificationWithIcon(
          "error",
          "Error",
          "Tipo de archivo no soportado."
        );
        return;
      }

      formData['extension'] = extension;

      const validadedToken = await auth.currentUser.getIdToken(true);
      const response = await guardarCapacitacion(validadedToken, formData);
      if (response.success) {
        const fileRef = storage
          .ref()
          .child(
            `capacitaciones/${formData.sucursal}/${formData.puesto}/${
              formData.clave + formData.clave.endsWith("-")
                ? formData.version
                : "-" + formData.version
            }${extension}`
          );
        await fileRef.put(formData.pdf.fileList[0].originFileObj);
        openNotificationWithIcon(
          "success",
          "Éxito",
          `Capacitación ${formData.nombre} guardada.`
        );
        form.resetFields();
        setFormData({});
        setCurrent(0);
      } else {
        openNotificationWithIcon(
          "warning",
          "Algo salió mal",
          response.error.msg ? response.error.msg : response.error
        );
        showErrorMessages(response.errors);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Algo salio mal",
        `La capacitación ${formData.nombre} no se guardo exitosamente.`
      );
      console.error("Error: ", error);
    }
    setLoading(false);
  };

  const puestoChange = (value, areas) => {
    setPuesto(value);
    setAreas(areas);
  };

  const sucursalChange = async (value) => {
    setSucursal(value);
  };

  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    const isMp4 = file.type === "video/mp4";
    if (!isPdf && !isMp4) {
      message.error("Solo puedes subir archivos PDF o MP4!");
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const showErrorMessages = (errorObject) => {
    if (!errorObject || !Array.isArray(errorObject)) {
      console.error("Invalid error object structure");
      return;
    }

    errorObject.forEach((error) => {
      const field = document.getElementById(error.field);
      if (field) {
        const errorMessage = document.createElement("div");
        errorMessage.className = "error-message"; // Puedes agregar estilos CSS para esta clase
        errorMessage.innerText = error.error;

        // Limpiar errores anteriores si existen
        const existingError = field.nextSibling;
        if (existingError && existingError.className === "error-message") {
          existingError.remove();
        }

        field.parentNode.insertBefore(errorMessage, field.nextSibling);
      } else {
        console.warn(`Field with id ${error.field} not found`);
      }
    });
  };

  const steps = [
    {
      title: "Información Básica",
      content: (
        <Form form={form} layout="vertical" onFinish={handleNext}>
          <Row gutter={8}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name="nombre"
                label="Nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el nombre de la capacitación",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name="clave"
                label="Clave"
                rules={[
                  { required: true, message: "Por favor ingrese la clave" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name="version"
                label="Versión"
                rules={[
                  { required: true, message: "Por favor ingrese la versión" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" htmlType="submit">
            Siguiente
          </Button>
        </Form>
      ),
    },
    {
      title: "Cargar PDF y Departamento",
      content: (
        <Form form={form} layout="vertical" onFinish={handleNext}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id="tipo"
              label="Tipo de documento"
              name="tipo"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un tipo de documento",
                },
              ]}
            >
              <Select placeholder="Seleccione el tipo de documento">
                <Option key="Norma" value="Norma">
                  Norma
                </Option>
                <Option key="Procedimiento" value="Procedimiento">
                  Procedimiento
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={6}></Col>
          <Form.Item
            name="pdf"
            label="Cargar PDF"
            rules={[{ required: true, message: "Por favor cargue el PDF" }]}
          >
            <Upload beforeUpload={beforeUpload} accept=".pdf,.mp4">
              <Button icon={<UploadOutlined />}>Haga clic para cargar</Button>
            </Upload>
          </Form.Item>

          <Col xs={24} md={12} lg={8}>
            <Suspense fallback={Loader}>
              <Sucursales onSucursalChange={sucursalChange} />
            </Suspense>
          </Col>

          {sucursal && (
            <>
              <Col xs={24} md={12} lg={8}>
                <Puestos onPuestoChange={puestoChange} sucursal={sucursal} />
              </Col>
            </>
          )}

          {puesto && areas.length > 0 && (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                id="areas"
                label="Área de trabajo"
                name="areas"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione las áreas de trabajo",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  value={additionalValues}
                  onChange={setAdditionalValues}
                  placeholder="Seleccione las áreas"
                >
                  {areas.map((area) => (
                    <Option key={area.nombre} value={area.nombre}>
                      {area.nombre}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Button type="primary" htmlType="submit">
            Siguiente
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => setCurrent(current - 1)}
          >
            Anterior
          </Button>
        </Form>
      ),
    },
    {
      title: "Resumen",
      content: (
        <>
          <Divider>Resumen de la Capacitación</Divider>
          <p>
            <strong>Nombre:</strong> {formData.nombre}
          </p>
          <p>
            <strong>Clave:</strong> {formData.clave}
          </p>
          <p>
            <strong>Versión:</strong> {formData.version}
          </p>
          <p>
            <strong>Tipo de documento:</strong> {formData.tipo}
          </p>
          <p>
            <strong>PDF:</strong>{" "}
            {formData.pdf ? formData.pdf.file.name : "No cargado"}
          </p>
          <p>
            <strong>Puesto:</strong> {formData.puesto}
          </p>
          {formData.areas && <>
            <p>
              <strong>Áreas:</strong> {formData.areas.join(',')}
            </p>
          </>}
          <Button type="primary" onClick={handleFinish} loading={loading}>
            Guardar
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => setCurrent(current - 1)}
          >
            Anterior
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: 24 }}>
        {steps[current].content}
      </div>
    </div>
  );
};

export default AgregarCapacitacion;
