import { Button, Form, Input, Radio, notification, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import React, { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import Sucursales from "../sucursales/Sucursales";
import { guardarCliente } from "../../servicios/clientes/servicios";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import Acceso from "./Acceso";

const AgregarCliente = () => {
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const Loader = <Spin indicator={antIcon} />;

  const openNotificationWithIcon = (type, title, msg) => {
    api[type]({
      message: title,
      description: msg,
    });
  };

  const onFinish = async (values) => {
    const accesos = [];
    const nuevosValores = { ...values };
    Object.keys(values).forEach((key) => {
      const match = key.match(/^(.+?)-(\d+)$/);

      if (match) {
        const [, field, index] = match;
        const idx = parseInt(index, 10);

        if (!accesos[idx]) {
          accesos[idx] = {};
        }

        accesos[idx][field] = values[key];
        delete nuevosValores[key];
      }
    });

    nuevosValores.info_accesos = accesos;

    console.log(nuevosValores);

    console.log("values: ", nuevosValores);
    setLoading(true);
    try {
      const tokenValid = await auth.currentUser.getIdToken(true);

      const response = await guardarCliente(tokenValid, nuevosValores);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Cliente agregado",
          `Cliente ${values.razon_social} se guardo exitosamente.`
        );
        form.resetFields();
        setAccess(false);
      } else {
        openNotificationWithIcon(
          "warning",
          "Algo salió mal",
          response.error.msg ? response.error.msg : response.error
        );
        showErrorMessages(response.errors);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Cliente no guardado",
        `El usuario ${values.name} no se guardo exitosamente.`
      );
      console.error("Error: ", error);
    }
    setLoading(false);
  };

  const handleRadioChange = (e) => {
    console.log("acceso change: ", e.target.value);
    if (e.target.value) {
      setAccess(true);
    } else {
      setAccess(false);
    }
  };

  const showErrorMessages = (errorObject) => {
    if (!errorObject || !Array.isArray(errorObject)) {
      console.error("Invalid error object structure");
      return;
    }

    errorObject.forEach((error) => {
      const field = document.getElementById(error.field);
      if (field) {
        const errorMessage = document.createElement("div");
        errorMessage.className = "error-message"; // Puedes agregar estilos CSS para esta clase
        errorMessage.innerText = error.error;

        // Limpiar errores anteriores si existen
        const existingError = field.nextSibling;
        if (existingError && existingError.className === "error-message") {
          existingError.remove();
        }

        field.parentNode.insertBefore(errorMessage, field.nextSibling);
      } else {
        console.warn(`Field with id ${error.field} not found`);
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Title level={3} className="ccg-center">
        Agregar Cliente
      </Title>
      <Form
        form={form}
        layout="vertical"
        name="agregar-cliente"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Razón Social"
              name="razon_social"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese la razón social del cliente",
                },
              ]}
            >
              <Input placeholder="Ingrese la razón social del cliente" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="RFC"
              name="rfc"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su rfc del cliente",
                },
                {
                  min: 8,
                  message: "Por favor ingrese un rfc mayor a 8 carácteres",
                },
              ]}
            >
              <Input placeholder="Ingrese su rfc del cliente" min={8} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Correo de contacto"
              name="contacto"
              rules={[
                {
                  required: true,
                  message:
                    "Por favor ingrese el correo de contacto del cliente",
                },
                {
                  type: "email",
                  message: "Por favor ingresa un correo electrónico válido",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Ingrese un correo de contacto del cliente"
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Celular"
              name="celular"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su número celular",
                },
                {
                  pattern: /^[0-9]{12}$/,
                  message: "Ingrese un número de teléfono válido (10 dígitos)",
                },
              ]}
            >
              <PhoneInput className="number" country={"mx"} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Suspense fallback={Loader}>
              <Sucursales></Sucursales>
            </Suspense>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item label="Crear acceso" name="acceso">
              <Radio.Group
                onChange={handleRadioChange}
                defaultValue={access}
                value={access}
              >
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {access && <Acceso />}
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                className="btn-success"
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AgregarCliente;
