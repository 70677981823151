import { Col, Divider, Form, Input, Button, Row } from "antd";
import React, { useState } from "react";

const Acceso = () => {
  const [accesos, setAccesos] = useState([{}]);

  const agregarAcceso = () => {
    setAccesos([...accesos, {}]);
  };

  const eliminarAcceso = (index) => {
    const nuevosAccesos = accesos.filter((_, i) => i !== index);
    setAccesos(nuevosAccesos);
  };

  return (
    <>
      <Divider>Información de acceso</Divider>
      {accesos.map((_, index) => (
        <Row key={index} gutter={[16, 16]} align="middle">
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id={`nombre-${index}`}
              label={`Nombre(s) Acceso ${index + 1}`}
              name={`nombre-${index}`}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su(s) nombre(s)",
                },
              ]}
            >
              <Input placeholder="Ingrese su(s) nombre(s)" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id={`ap_paterno-${index}`}
              label={`Apellido Paterno Acceso ${index + 1}`}
              name={`ap_paterno-${index}`}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su apellido paterno",
                },
              ]}
            >
              <Input placeholder="Ingrese su apellido paterno" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id={`correo-${index}`}
              label={`Correo Acceso ${index + 1}`}
              name={`correo-${index}`}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el correo",
                },
                {
                  type: "email",
                  message: "Por favor ingresa un correo electrónico válido",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Ingrese un correo del empleado"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id={`password-${index}`}
              label={`Contraseña Acceso ${index + 1}`}
              name={`password-${index}`}
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa la contraseña",
                },
                {
                  min: 8,
                  message: "La contraseña debe tener al menos 8 caracteres",
                },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!¡%*?&#¿])[A-Za-z\d@$!¡%*?&#¿]{8,}$/,
                  message:
                    "La contraseña debe incluir al menos una mayúscula, una minúscula, un número y un carácter especial (@$!%*?&#¿)",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              id={`confirmar_password-${index}`}
              label={`Confirmar Contraseña Acceso ${index + 1}`}
              name={`confirmar_password-${index}`}
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa la contraseña",
                },
                {
                  min: 8,
                  message: "La contraseña debe tener al menos 8 caracteres",
                },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!¡%*?&#¿])[A-Za-z\d@$!¡%*?&#¿]{8,}$/,
                  message:
                    "La contraseña debe incluir al menos una mayúscula, una minúscula, un número y un carácter especial (@$!%*?&#¿)",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          {index > 0 && (
            <Col xs={24} md={12} lg={8}>
              <Button
                type="dashed"
                danger
                onClick={() => eliminarAcceso(index)}
                style={{ marginTop: 16 }}
              >
                Eliminar acceso
              </Button>
            </Col>
          )}
        </Row>
      ))}
      <Row>
        <Col span={24}>
          <Button
            type="primary"
            onClick={agregarAcceso}
            style={{ float: 'right', marginBottom: '20px' }}
          >
            Agregar otro acceso
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Acceso;
