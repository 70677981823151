import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Form, message } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Title from 'antd/es/typography/Title';
import Empleados from "../empleados/Empleados";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useAuth } from '../Context';
import { firestore } from '../../firebase';

const localizer = momentLocalizer(moment);
moment.locale('es');

const BuscarSolicitudRH = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmpleado, setSelectedEmpleado] = useState(null);
  const [filter, setFilter] = useState('');
  const [form] = Form.useForm();
  const [events, setEvents] = useState([]);
  const { sucursal, isAdmin, currentUser } = useAuth();
  const [date, setDate] = useState(new Date());
  const anio = moment(date).year();
  const mes = moment(date).month() + 1;

  const getEventsForMonth = (sucursal, year, month, isAdmin, userId) => {
    const eventsPromises = [];

    if (isAdmin) {
      console.log('es admin: ', `sucursal/${sucursal}/recursos humanos/permiso/${year}-${month < 10 ? '0'+month : month}`);
      const permisosRef = collection(firestore, `sucursal/${sucursal}/recursos humanos/permiso/${year}-${month < 10 ? '0'+month : month}`);
      const vacacionesRef = collection(firestore, `sucursal/${sucursal}/recursos humanos/vacaciones/${year}-${month < 10 ? '0'+month : month}`);
      
      eventsPromises.push(
        new Promise(resolve => {
          onSnapshot(permisosRef, (snapshot) => {
            const permisos = snapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
              title: doc.data().motivo,
              start: moment(doc.data().dia).toDate(),
              end: moment(doc.data().dia).toDate()
            }));
            resolve(permisos);
          });
        })
      );

      eventsPromises.push(
        new Promise(resolve => {
          onSnapshot(vacacionesRef, (snapshot) => {
            const vacaciones = snapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
              title: "Vacaciones",
              start: moment(doc.data().inicio).toDate(),
              end: moment(doc.data().fin).toDate()
            }));
            resolve(vacaciones);
          });
        })
      );

    } else {
      const permisosRef = query(
        collection(firestore, `sucursal/${sucursal}/recursos humanos/permiso/${year}-${month < 10 ? '0'+month : month}`),
        where('empleado', '===', userId)
      );
      const vacacionesRef = query(
        collection(firestore, `sucursal/${sucursal}/recursos humano/vacaciones/${year}-${month < 10 ? '0'+month : month}`),
        where('empleado', '===', userId)
      );

      eventsPromises.push(
        new Promise(resolve => {
          onSnapshot(permisosRef, (snapshot) => {
            const permisos = snapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
              title: doc.data().motivo,
              start: moment(doc.data().dia).toDate(),
              end: moment(doc.data().dia).toDate()
            }));
            resolve(permisos);
          });
        })
      );

      eventsPromises.push(
        new Promise(resolve => {
          onSnapshot(vacacionesRef, (snapshot) => {
            const vacaciones = snapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
              title: "Vacaciones",
              start: moment(doc.data().inicio).toDate(),
              end: moment(doc.data().fin).toDate()
            }));
            resolve(vacaciones);
          });
        })
      );
    }

    return Promise.all(eventsPromises).then(eventsArray => eventsArray.flat());
  };

  useEffect(() => {
    const loadEvents = async () => {
      // Limpiar eventos antes de cargar nuevos
      setEvents([]);
      
      const previousMonth = moment(date).subtract(1, 'months');
      const nextMonth = moment(date).add(1, 'months');

      const [prevEvents, currentEvents, nextEvents] = await Promise.all([
        getEventsForMonth(sucursal, previousMonth.year(), previousMonth.month() + 1, isAdmin, currentUser.uid),
        getEventsForMonth(sucursal, anio, mes, isAdmin, currentUser.uid),
        getEventsForMonth(sucursal, nextMonth.year(), nextMonth.month() + 1, isAdmin, currentUser.uid)
      ]);

      setEvents([...prevEvents, ...currentEvents, ...nextEvents]);
    };

    loadEvents();
  }, [sucursal, anio, mes, isAdmin, currentUser.uid, date]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setIsModalVisible(true);
  };

  const handleCancelPermission = () => {
    form.validateFields().then(values => {
      message.success(`Permiso cancelado por: ${values.reason}`);
      setIsModalVisible(false);
    }).catch(info => {
      console.log('Validation Failed:', info);
    });
  };

  const handleConfirmPermission = () => {
    message.success('Permiso confirmado');
    setIsModalVisible(false);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const filteredEvents = selectedEmpleado
    ? events.filter(event => event.empleado === selectedEmpleado)
    : events;

  return (
    <>
    <Title level={3} className="ccg-center">Permisos</Title>
      <Empleados search onSelect={setSelectedEmpleado} />
      <Calendar
        localizer={localizer}
        events={filteredEvents}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        messages={{
          week: 'Semana',
          work_week: 'Semana laboral',
          day: 'Día',
          month: 'Mes',
          previous: 'Atrás',
          next: 'Siguiente',
          today: 'Hoy',
          agenda: 'Agenda',
          noEventsInRange: 'No hay eventos en este rango',
          showMore: total => `+ Ver más (${total})`
        }}
        style={{ height: 500 }}
        onSelectEvent={handleSelectEvent}
        onNavigate={handleDateChange}
      />
      <Modal 
        title="Detalles del Permiso" 
        open={isModalVisible} 
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedEvent && (
          <div>
            <p><strong>Nombre:</strong> {selectedEvent.name}</p>
            <p><strong>Departamento:</strong> {selectedEvent.department}</p>
            <p><strong>Fechas:</strong> {moment(selectedEvent.startDate).format('YYYY-MM-DD')} - {moment(selectedEvent.endDate).format('YYYY-MM-DD')}</p>
            <p><strong>Razón:</strong> {selectedEvent.reason}</p>
            <Form form={form} layout="vertical" onFinish={handleCancelPermission}>
              <Form.Item name="reason" label="Motivo de cancelación" rules={[{ required: true, message: 'Por favor ingrese el motivo de cancelación' }]}>
                <Input.TextArea rows={4} />
              </Form.Item>
              <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} style={{ marginRight: 8 }}>
                Cancelar Permiso
              </Button>
              <Button type="default" onClick={handleConfirmPermission} icon={<CheckOutlined />}>
                Confirmar Permiso
              </Button>
            </Form>
          </div>
        )}
      </Modal>
    </>
  );
};

export default BuscarSolicitudRH;
