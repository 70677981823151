import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { getAll } from "../../servicios/sucursales/servicios";

const Sucursales = ({ onSucursalChange }) => {
  const { Option } = Select;
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const tokenValid = await auth.currentUser.getIdToken(true);
      getAll(tokenValid)
        .then((data) => {
          console.log("respuesta back: ", data);
          setData(data.info);
        })
        .catch((err) => {
          setData([]);
        });
    };

    loadData();
  }, []);

  return (
    <Form.Item
      label="Sucursal"
      name="sucursal"
      rules={[
        {
          required: true,
          message: "Por favor selecciona una sucursal",
        },
      ]}
    >
      <Select defaultValue={"0"} onChange={onSucursalChange}>
        <Option value="0" disabled>
          Selecciona una sucursal
        </Option>
        {data.map((item) => (
          <Option key={item.name}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Sucursales;
