import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card, Dropdown, Input, Menu, Space, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";

const { Search } = Input;
const { Option } = Select;

const initialProjects = [
  { id: "project-1", content: "Proyecto 1", status: "Creado" },
  { id: "project-2", content: "Proyecto 2", status: "Creado" },
  { id: "project-3", content: "Proyecto 3", status: "En Proceso" },
  { id: "project-4", content: "Proyecto 4", status: "En Proceso" },
  { id: "project-5", content: "Proyecto 5", status: "Entregado" },
  { id: "project-6", content: "Proyecto 6", status: "Entregado" },
  { id: "project-7", content: "Proyecto 7", status: "Terminado" },
  { id: "project-8", content: "Proyecto 8", status: "Terminado" },
];

const BuscarProyecto = () => {
  const [projects, setProjects] = useState(initialProjects);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const statuses = ["Creado", "En Proceso", "Entregado", "Terminado"];

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return; // No se ha soltado en un destino válido
    if (destination.droppableId === source.droppableId && destination.index === source.index) return; // No hay cambios en la posición o el mismo estado

    const updatedProjects = Array.from(projects);
    const [removed] = updatedProjects.splice(source.index, 1);
    updatedProjects.splice(destination.index, 0, removed);

    const draggedProject = projects.find((project) => project.id === result.draggableId);
    if (draggedProject) draggedProject.estatus = destination.droppableId;

    setProjects(updatedProjects);
  };

  const menuOptions = (project) => (
    <Menu>
      <Menu.Item onClick={() => console.log("Ver detalles", project.id)}>
        Ver detalles
      </Menu.Item>
      {project.estatus === "Entregado" && (
        <>
          <Menu.Item onClick={() => console.log("Ver reportes", project.id)}>
            Ver reportes
          </Menu.Item>
          <Menu.Item onClick={() => console.log("Ver pagos", project.id)}>
            Ver pagos
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const filteredProjects = projects.filter(
    (project) =>
      project.content.toLowerCase().includes(filterName.toLowerCase()) &&
      (!filterStatus || project.estatus === filterStatus)
  );

  return (
    <>
      <Title level={3} className="ccg-center">Proyectos de Estructura</Title>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Buscar por nombre"
          onChange={(e) => setFilterName(e.target.value)}
          style={{ width: 200 }}
        />
        <Select
          placeholder="Filtrar por status"
          value={filterStatus}
          onChange={(value) => setFilterStatus(value)}
          style={{ width: 150 }}
        >
          <Option value="">Todos</Option>
          {statuses.map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Space>
      <div style={{ overflowX: "auto" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div style={{ display: "flex" }}>
            {statuses.map((status) => (
              <Droppable key={status} droppableId={status}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver ? "lightsalmon" : "antiquewhite",
                      padding: 4,
                      minWidth: 200,
                      width: 250,
                      minHeight: 500,
                      margin: 8,
                      borderRadius: '10px'
                    }}
                  >
                    <h2 style={{textAlign: 'center'}}>{status}</h2>
                    {filteredProjects
                      .filter((project) => project.estatus === status)
                      .map((project, index) => (
                        <Draggable key={project.id} draggableId={project.id} index={index} isDragDisabled={false}>
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <Card
                                title={project.content}
                                extra={
                                  <Dropdown overlay={() => menuOptions(project)} trigger={["click"]}>
                                    <MoreOutlined />
                                  </Dropdown>
                                }
                                style={{ marginBottom: 8 }}
                              >
                                Estado: {project.estatus}
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
    </>
  );
};

export default BuscarProyecto;
