import config from "../config";

const apiUrl = config.apiUrl;

export const guardarAsistencia = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body }),
    };

    fetch(apiUrl + "/recursos-humanos/asistencia/crear", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(false);
      });
  });
};

export const guardarSolicitud = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body }),
    };

    fetch(apiUrl + "/recursos-humanos/solicitud/crear", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(false);
      });
  });
};
