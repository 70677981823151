import { Avatar, Button, Form, Input } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useAuth } from "../Context";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useNotification } from "../NotificationContext";
import NoPermisos from "../NoPermisos";
// import { jwtDecode } from "jwt-decode";

const Perfil = () => {
  const navigate = useNavigate();
  const { currentUser, permisos, isAdmin } = useAuth();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const openNotificationWithIcon = useNotification();
  // const [profile, setProfile] = useState("");
  
  // const [avatarUrl, setAvatarUrl] = useState(currentUser.photoURL);

  const onFinish = async (values) => {
    try {
      setButtonDisabled(true);

      await currentUser.updateProfile({
        displayName: values.displayName,
      });

      openNotificationWithIcon(
        "success",
        "Información actualizada",
        "Se actualizó con éxito la información de perfil"
      );
      navigate("/sistema/perfil", { replace: true });
    } catch (error) {
      console.error("Error al actualizar la información del usuario:", error);
    }
    setButtonDisabled(false);
  };

  // const handleAvatarChange = (info) => {
  //   if (info.file.estatus === "done") {
  //     // Obtener la URL de la imagen subida y actualizar el estado
  //     setAvatarUrl(info.file.response.url);
  //   }
  // };

  useEffect(() => {
    isAdmin ? console.log('Es admin') : console.log('permisos: ', permisos);
  }, [isAdmin, permisos]);

  return (
    <>
    {(isAdmin || permisos.perfil) ? 
    <>
      <Title level={2} className="ccg-center">Perfil</Title>
      <div style={{ maxWidth: 400, margin: "auto" }}>
        <Form
          name="profileForm"
          onFinish={onFinish}
          initialValues={{
            displayName: currentUser.displayName ? currentUser.displayName : "",
            email: currentUser.email,
          }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item label="Avatar">
            {/* <Upload
                name="avatar"
                onChange={handleAvatarChange}
                showUploadList={true}
              >
                <Tooltip title="Editar"> */}
            <Avatar
              src={currentUser.photoURL}
              size={92}
              icon={<UserOutlined />}
            />
            {/* </Tooltip>
                <Button icon={<UploadOutlined />}>Subir Imagen</Button>
              </Upload> */}
          </Form.Item>
          <Form.Item
            label="Nombre"
            name="displayName"
            rules={[
              {
                required: true,
                message: "Por favor ingresa tu nombre completo",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[{ type: "email", message: "Correo electrónico inválido" }]}
          >
            <Input disabled />
          </Form.Item>
          {/* <Form.Item label="Puesto" name="position">
              <Input defaultValue={profile} disabled />
            </Form.Item> */}
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              // loading={loading}
              disabled={buttonDisabled}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  : <>
    <NoPermisos />
  </>} 
    </>
  );
};

export default Perfil;
