import React, { useState, useEffect } from "react";
import { Table, Input, Row, Col, DatePicker } from "antd";
import moment from "moment";
import "moment/locale/es";
import { firestore } from "../../firebase";
import { collection, query, onSnapshot, getDoc, doc } from "firebase/firestore";
import { useAuth } from "../Context";

moment.locale("es");

const daysOfWeek = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

const BuscarAsistencia = () => {
  const [filterName, setFilterName] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(moment());
  const { sucursal } = useAuth();

  useEffect(() => {
    console.log("selected week: ", selectedWeek);
    const year = selectedWeek.year();
    console.log("year: ", year);
    const numberOfWeek = selectedWeek.week();
    console.log("week: ", numberOfWeek);

    const asistenciaRef = collection(
      firestore,
      `sucursal/${sucursal}/asistencia/${year}/${numberOfWeek}`
    );
    const q = query(asistenciaRef);

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const data = await Promise.all(
        snapshot.docs.map(async (element) => {
          const asistencias = element.data();
          const empleadoRef = doc(
            firestore,
            `sucursal/${sucursal}/empleado/${element.id}`
          );
          const empleadoData = await getDoc(empleadoRef);
          const formattedAsistencias = {
            id: element.id,
            name:
              empleadoData.data().nombre +
              " " +
              empleadoData.data().ap_paterno +
              " " +
              empleadoData.data().ap_materno,
          };

          for (let i = 0; i < daysOfWeek.length; i++) {
            const asistencia = asistencias[i];
            console.log("día: ", asistencias[i]);
            if (asistencia) {
              if (i >= 0 && i < daysOfWeek.length - 1) {
                formattedAsistencias[daysOfWeek[i]] = asistencia.asistencia
                  ? `Entrada: ${asistencia.entrada} \n
                 ${
                   asistencia.salidaComida
                     ? `Comida salida: ${asistencia.salidaComida}\n`
                     : ""
                 }
                 ${
                   asistencia.entradaComida
                     ? `Comida entrada: ${asistencia.entradaComida}\n`
                     : ""
                 }
                 Salida: ${asistencia.salida} \n`
                  : asistencia.motivoInasistencia || "Inasistencia";
              } else if (i === daysOfWeek.length - 1) {
                formattedAsistencias[daysOfWeek[i]] = asistencia.asistencia
                  ? `Entrada: ${asistencia.entrada} \n
                     Salida: ${asistencia.salida} \n`
                  : asistencia.motivoInasistencia || "Inasistencia";
              }
            } else {
              formattedAsistencias[daysOfWeek[i]] = "N/A";
            }
          }

          return formattedAsistencias;
        })
      );

      console.log("data: ", data);
      setDataSource(data);
    });

    return () => unsubscribe();
  }, [selectedWeek, sucursal]);

  // const filteredData = dataSource.filter(attendance =>
  //   attendance.name.toLowerCase().includes(filterName.toLowerCase())
  // );

  console.log("filteredData: ", dataSource);

  const handleSelectWeek = (value) => {
    setSelectedWeek(value);
  };

  const columns = [
    {
      title: "Empleado",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...daysOfWeek.map((day) => ({
      title: day,
      dataIndex: day,
      key: day,
      render: (text) => (
        <div
          style={{
            whiteSpace: "pre-line",
            padding: 0,
            margin: 0,
            lineHeight: 1,
          }}
        >
          {text}
        </div>
      ),
    })),
  ];

  const disabledDate = (current) => {
    return (
      current < moment().subtract(6, "month") ||
      current > moment() ||
      current.day() === 0
    );
  };

  const filteredData = dataSource.filter((attendance) =>
    attendance.name.toLowerCase().includes(filterName.toLowerCase())
  );

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Input.Search
            placeholder="Buscar por nombre de empleado"
            onChange={(e) => setFilterName(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        </Col>
        <Col span={12}>
          <DatePicker.WeekPicker
            onChange={handleSelectWeek}
            disabledDate={disabledDate}
            format="YYYY-MM-DD"
            style={{ marginBottom: 16, width: "100%" }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        locale={{
          emptyText: "No hay asistencias",
          filterConfirm: "Aceptar",
          filterReset: "Reiniciar",
          selectAll: "Seleccionar todo",
          selectInvert: "Invertir selección",
          sortTitle: "Ordenar",
          filterTitle: "Filtro",
          filterEmptyText: "No hay filtros",
          triggerDesc: "Ordenar descendente",
          triggerAsc: "Ordenar ascendente",
          cancelSort: "Cancelar",
        }}
        bordered
        expandable
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          locale: {
            items_per_page: "por página",
            jump_to: "Ir a",
            jump_to_confirm: "Confirmar",
            page: "",
            prev_page: "Página anterior",
            next_page: "Página siguiente",
            prev_5: "5 páginas anteriores",
            next_5: "5 páginas siguientes",
            prev_3: "3 páginas anteriores",
            next_3: "3 páginas siguientes",
          },
        }}
        scroll={{ x: "max-content" }}
        rowKey="id"
      />
    </div>
  );
};

export default BuscarAsistencia;
