import { EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Input, Menu, message, Spin, Switch } from "antd";
import Title from "antd/es/typography/Title";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { auth, firestore } from "../../firebase";
import { actualizarStatusAcceso } from "../../servicios/clientes/servicios";
import { useAuth } from "../Context";
import { useNotification } from "../NotificationContext";

export const ConfigurarCliente = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const { sucursal, isAdmin } = useAuth();
  const openNotificationWithIcon = useNotification();
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [updating, setUpdating] = useState(null);

  const columns = [
    {
      name: "Cliente",
      selector: (row) => row.cliente,
      sortable: true,
    },
    {
      name: "Correo",
      selector: (row) => row.correo,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Estado",
      cell: (row) => {
        const status = row.estatus === "Activo" ? true : false;
        return <>
        {updating === row.correo ? <Spin></Spin> : 
        <>
          <Switch
          checked={status}
          onChange={(checked) => handleStatusChange(row, checked)}
          />
        </>
        }
        </>
      }
    },
    {
      name: "Acciones",
      cell: (row) => (
        <Dropdown overlay={() => menu(row)} trigger={["click"]}>
          <Button type="default" icon={<EllipsisOutlined />}></Button>
        </Dropdown>
      ),
      sortable: false,
      width: "100px",
    },
  ];

  const handleStatusChange = async (record, checked) => {
    console.log('actualizando acceso: ', record);
    setUpdating(record.correo);
    try {
      const updatedStatus = record.estatus === 'Activo' ? 'Inactivo' : 'Activo';
      console.log('actualiando: ', checked, ' id: ', record);
      const body = {
        cliente: record.uid,
        sucursal,
        status: updatedStatus
      }
      const validatedToken = await auth.currentUser.getIdToken(true);
      const response = await actualizarStatusAcceso(validatedToken, body);
      if (response.success) {
        await firestore.collection(`sucursal/${sucursal}/cliente/${record.cliente}/accesos/`).doc(record.uid).update({ estatus: updatedStatus });
        openNotificationWithIcon(
          "success",
          "Información actualizada",
          "Se " + updatedStatus + " " + record.nombre
        );
      } else {
        openNotificationWithIcon(
          "warning",
          "Algo salió mal",
          response.error.msg ? response.error.msg : response.error
        );
      }
    } catch (error) {
        openNotificationWithIcon(
          "error",
          "Error",
          "Error al actualizar el estado del empleado"
        );
      console.error("Error updating client access status: ", error);
    }
    setUpdating(null);
  };

  const handleEdit = (record) => {
    // onEdit(record.id);
    message.success("Empleado actualizado exitosamente: ");
  };

  const handleDisable = async (record, status) => {
    console.log("record: ", record);
    try {
      const body = {};
      body["status"] = status ? "Activo" : "Inactivo";
      body["uid"] = record.id;
    } catch (error) {
      console.error("Error al deshabilitar el usuario con UID:", error);
    }
  };

  const handleMenuClick = (action, record) => {
    if (action === "edit") {
      handleEdit(record);
    } else if (action === "disable") {
      handleDisable(record, false);
    } else if (action === "active") {
      handleDisable(record, true);
    }
  };

  const menu = (record) => (
    <Menu onClick={(e) => handleMenuClick(e.key, record)}>
      {record.estatus === "Activo" ? (
        <>
          <Menu.Item key="disable" icon={<EyeOutlined />}>
            Deshabilitar
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="active" icon={<EyeOutlined />}>
            Activar
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const filteredData = clientes.filter(
    (item) =>
      String(item.cliente).toLowerCase().includes(searchText.toLowerCase()) ||
      String(item.correo).toLowerCase().includes(searchText.toLowerCase()) ||
      String(item.fullName).toLowerCase().includes(searchText.toLowerCase())
      // String(item.estatus).toLowerCase().includes(searchText.toLowerCase())
  );

  const conditionalRowStyles = [
    {
      when: (row) => true,
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDeleteRows = () => {
    if (
      window.confirm(`¿Estas seguro?:\r ${selectedRows.map((r) => r.name)}?`)
    ) {
      setToggleCleared(!toggleCleared);
      setSelectedRows([]);
    }
  };

  function convertArrayOfObjectsToCSV(rows) {
    const header = clientes[0];
    delete header.id;

    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(header);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    rows.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    if (array.length === 0) {
      message.info("Debe seleccionar los empleados que desea exportar");
      return;
    }
    array = array.map((row) => {
      const dataWithoutId = { ...row };
      delete dataWithoutId.id;
      return dataWithoutId;
    });

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Empleados.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ selectedRows, onExport }) => (
    <Button
      style={{ zIndex: 100, position: "absolute" }}
      onClick={() => onExport(selectedRows)}
    >
      Export
    </Button>
  );

  const actionsMemo = React.useMemo(
    () => (
      <Export
        selectedRows={selectedRows}
        onExport={(selectedRows) => downloadCSV(selectedRows)}
      />
    ),
    [downloadCSV, selectedRows]
  );

  useEffect(() => {
    const q = collection(firestore, `sucursal/${sucursal}/cliente`);
    const unsubscribeFromClientes = onSnapshot(q, (snapshot) => {
      const unsubscribeFromAccesos = [];
      
      const clientesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      clientesData.forEach((cliente) => {
        const accesosCollection = collection(firestore, `sucursal/${sucursal}/cliente/${cliente.id}/accesos`);
        
        const unsubscribe = onSnapshot(accesosCollection, (accesosSnapshot) => {
          const accesosData = accesosSnapshot.docs.map((accesoDoc) => ({
            uid: accesoDoc.id,
            fullName: `${accesoDoc.data().nombre} ${accesoDoc.data().ap_paterno}`,
            ...accesoDoc.data(),
            cliente: cliente.id,
          }));

          setClientes((prevClientes) => {
            const updatedClientes = prevClientes.filter((c) => c.cliente !== cliente.id);
            return [...updatedClientes, ...accesosData];
          });
        });

        unsubscribeFromAccesos.push(unsubscribe);
      });

      setLoading(false);
      return () => unsubscribeFromAccesos.forEach((unsubscribe) => unsubscribe());
    });

    return () => unsubscribeFromClientes();
  }, [sucursal]);

  return (
    <>
      <Title level={3} className="ccg-center">
        Configuración de Acceso a Clientes
      </Title>

      <Form.Item
        label="Buscar"
        name="search"
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 8,
        }}
        rules={[
          {
            required: true,
            message: "Por favor ingresa un valor.",
          },
        ]}
      >
        <Input
          placeholder="Buscar..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          size="large"
        />
      </Form.Item>
      <DataTable
        keyField="correo"
        className="ttbl"
        noDataComponent={"No se encontro información"}
        columns={columns}
        data={filteredData}
        progressPending={loading}
        progressComponent={<Title level={4}>Cargando datos...</Title>}
        conditionalRowStyles={conditionalRowStyles}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página:",
          rangeSeparatorText: "de",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "Todos",
        }}
        striped
        highlightOnHover
        responsive
        scrollY={400}
        defaultSortField="razon_social"
        defaultSortAsc
        selectableRows
        contextMessage={{
          singular: "cliente",
          plural: "clientes",
          message: "seleccionado(s)",
        }}
        onSelectedRowsChange={handleRowSelected}
        actions={actionsMemo}
        clearSelectedRows={toggleCleared}
      />
    </>
  );
};

export default ConfigurarCliente;
