import config from '../config';

const apiUrl = config.apiUrl;

export const guardarCliente = (token, values) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...values }),
    };

    fetch(
      apiUrl+"/cliente/crear",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(false);
      });
  });
};

export const actualizarStatus = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body })
    };

    fetch(
      apiUrl+"/cliente/actualizar",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al actualizar status en capacitación: ", err);
        reject(false);
      });
  });
};

export const actualizarStatusAcceso = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body })
    };

    fetch(
      apiUrl+"/cliente/actualizar-acceso",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al actualizar status en capacitación: ", err);
        reject(false);
      });
  });
};
