import config from '../config';

const apiUrl = config.apiUrl;

export const getAll = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({...body})
    };

    fetch(
      apiUrl+"/solicitud/obtener",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al obtener empleados: ", err);
        reject(false);
      });
  });
};

export const sendAnswer = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body })
    };

    fetch(
      apiUrl+"/solicitud/responder",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al responder solicitud: ", err);
        reject(false);
      });
  });
};
