import config from '../config';

const apiUrl = config.apiUrl;

export const crearPuesto = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body }),
    };

    fetch(
      apiUrl+"/puesto/crear",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(false);
      });
  });
};

export const obtenerPuestos = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({...body})
    };

    fetch(
      apiUrl+"/puesto/obtener",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al obtener puestos: ", err);
        reject(false);
      });
  });
};

export const eliminarPuesto = (token, values) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...values }),
    };

    fetch(apiUrl + "/puesto/eliminar", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al eliminar puesto: ", err);
        reject(false);
      });
  });
};

export const crearArea = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...body
      }),
    };

    fetch(apiUrl + "/puesto/area/crear", requestOptions)
    .then((res) => res.json())
    .then((data) => {
      resolve(data);
    })
    .catch((err) => {
      reject(false);
    });
  });
};

export const eliminarArea = (token, values) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...values }),
    };

    fetch(apiUrl + "/puesto/area/eliminar", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al eliminar área: ", err);
        reject(false);
      });
  });
};

export const agregarPermisos = (token, values) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...values }),
    };

    fetch(apiUrl + "/puesto/permiso/agregar", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al agregar permisos: ", err);
        reject(false);
      });
  });
};

