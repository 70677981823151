import config from '../config';

const apiUrl = config.apiUrl;

export const guardarCapacitacion = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body })
    };

    fetch(
      apiUrl+"/capacitacion/guardar",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al guardar capacitación: ", err);
        reject(false);
      });
  });
};

export const actualizarStatus = (token, body) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...body })
    };

    fetch(
      apiUrl+"/capacitacion/actualizar",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("Error al actualizar status en capacitación: ", err);
        reject(false);
      });
  });
};
