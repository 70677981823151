import React, { useState } from 'react';
import { Card, Avatar, Switch, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { firestore } from "../../firebase"; 
import { useNotification } from '../NotificationContext';
import { useAuth } from "../Context";
import { auth } from "../../firebase";
import { actualizarEstatus } from '../../servicios/empleados/servicios';

const Empleado = ({ employee }) => {
  const { nombre, ap_paterno, edad, puesto, fecha_ingreso, celular, fotoUrl, estatus } = employee;
  const [updating, setUpdating] = useState(false);
  const openNotificationWithIcon = useNotification();
  const { sucursal } = useAuth();
  
  const handleToggleStatus = async (employee) => {
    setUpdating(true);
    try {
      const updatedStatus = employee.estatus === 'Activo' ? 'Inactivo' : 'Activo';
      console.log('actualiando: ', updatedStatus, ' id: ', employee);
      const body = {
        uid: employee.id,
        sucursal,
        status: updatedStatus
      }
      const validatedToken = await auth.currentUser.getIdToken(true);
      const response = await actualizarEstatus(validatedToken, body);
      if (response.success) {
        await firestore.collection('sucursal/Centro/empleado').doc(employee.id).update({ estatus: updatedStatus });
        openNotificationWithIcon(
          "success",
          "Información actualizada",
          "Se " + updatedStatus + " " + employee.nombre
        );
      } else {
        openNotificationWithIcon(
          "warning",
          "Algo salió mal",
          response.error.msg ? response.error.msg : response.error
        );
      }
    } catch (error) {
        openNotificationWithIcon(
          "error",
          "Error",
          "Error al actualizar el estado del empleado"
        );
      console.error("Error updating employee status: ", error);
    }
    setUpdating(false);
  };

  return (
    <Card>
      <div style={{ position: 'absolute', top: 16, right: 16 }}>
        {updating ? <><Spin></Spin></> : 
        <Switch
          checked={estatus === 'Activo'}
          onChange={() => handleToggleStatus(employee)}
          checkedChildren="Activo"
          unCheckedChildren="Inactivo"
        />
        }
      </div>
      <Avatar size={64} icon={<UserOutlined />} src={fotoUrl} />
      <h2>{nombre + ' ' + ap_paterno}</h2>
      <p className="ccg-reset"><b>Puesto: </b>{puesto}</p>
      <p className="ccg-reset"><b>Fecha de Ingreso: </b>{fecha_ingreso}</p>
      <p className="ccg-reset"><b>Teléfono: </b>{celular}</p>
      <p className="ccg-reset"><b>Edad: </b>{edad}</p>
    </Card>
  );
};

export default Empleado;
