import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import "./fonts/Griffon.ttf"
import SistemaLogin from "./componentes/Login";
import { useAuth } from "./componentes/Context";
import AdminLayout from "./componentes/AdminLayout";
import BuscarEmpleado from "./componentes/empleados/BuscarEmpleado";
import AgregarEmpleado from "./componentes/empleados/AgregarEmpleado";
import BuscarCliente from "./componentes/clientes/BuscarCliente";
import AgregarCliente from "./componentes/clientes/AgregarCliente";
import Bienvenida from "./componentes/Bienvenida";
import ConfigurarPuesto from "./componentes/configuracion/ConfigurarPuesto";
import ConfigurarCliente from "./componentes/configuracion/ConfigurarCliente";
import Perfil from "./componentes/empleados/Perfil";
import { Spin } from "antd";
import Dashboard from "./componentes/Dashboard";
import BuscarCapacitacion from "./componentes/capacitaciones/BuscarCapacitacion";
import AgregarCapacitacion from "./componentes/capacitaciones/AgregarCapacitacion";
import Normas from "./componentes/capacitaciones/Normas";
import Chat from "./componentes/chats/Chat";
import BuscarProyectoEstructura from "./componentes/proyectos/BuscarProyectoEstructura";
import AgregarProyectoEstructura from "./componentes/proyectos/AgregarProyectoEstructura";
import BuscarProyectoConcreto from "./componentes/proyectos/BuscarProyectoConcreto";
import AgregarProyectoConcreto from "./componentes/proyectos/AgregarProyectoConcreto";
import BuscarSolicitudRH from "./componentes/rh/BuscarSolicitudRH";
import AgregarSolicitud from "./componentes/rh/AgregarSolicitud";
import BuscarAsistencia from "./componentes/rh/BuscarAsistencia";
import AgregarAsistencia from "./componentes/rh/AgregarAsistencia";
import BuscarVehiculo from "./componentes/vehiculos/BuscarVehiculo";
import AgregarVehiculo from "./componentes/vehiculos/AgregarVehiculo";
import AgregarCarga from "./componentes/vehiculos/AgregarCarga";
import BuscarSolicitud from "./componentes/solicitudes/BuscarSolicitud";
import Procedimientos from "./componentes/capacitaciones/Procedimientos";
import AgregarProyecto from "./componentes/proyectos/AgregarProyecto";

function App() {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" tip="Cargando..." />
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            currentUser ? <Navigate to="/sistema/bienvenida" /> : <SistemaLogin />
          }
        />
        <Route
          path="/sistema"
          element={currentUser ? <AdminLayout /> : <Navigate to="/" />}
        >
          <Route path="bienvenida" element={<Bienvenida />} />
          
          <Route path="perfil" element={<Perfil />} />

          <Route path="sucursal" element={<Dashboard />} />

          <Route path="empleado/buscar" element={<BuscarEmpleado />} />
          <Route path="empleado/agregar" element={<AgregarEmpleado />} />
          
          <Route path="cliente/buscar" element={<BuscarCliente />} />
          <Route path="cliente/agregar" element={<AgregarCliente />} />

          <Route path="orden-de-servicio/agregar" element={<AgregarEmpleado />} />
          <Route path="orden-de-servicio/buscar" element={<AgregarEmpleado />} />
          
          <Route path="proyectos/agregar" element={<AgregarProyecto />} />
          <Route path="proyectos/concreto/buscar" element={<BuscarProyectoConcreto />} />
          <Route path="proyectos/concreto/agregar" element={<AgregarProyectoConcreto />} />
          <Route path="proyectos/estructura/buscar" element={<BuscarProyectoEstructura />} />
          <Route path="proyectos/estructura/agregar" element={<AgregarProyectoEstructura />} />

          <Route path="capacitacion/buscar" element={<BuscarCapacitacion />} />
          <Route path="capacitacion/agregar" element={<AgregarCapacitacion />} />
          <Route path="capacitacion/norma/buscar" element={<Normas />} />
          <Route path="capacitacion/procedimiento/buscar" element={<Procedimientos />} />
          
          <Route path="vehiculo/buscar" element={<BuscarVehiculo />} />
          <Route path="vehiculo/agregar" element={<AgregarVehiculo />} />
          <Route path="vehiculo/carga-gas" element={<AgregarCarga />} />
          
          <Route path="chat" element={<Chat />} />
          
          {/* Recursos Humanos */}
          <Route path="solicitud/buscar" element={<BuscarSolicitudRH />} />
          <Route path="solicitud/agregar" element={<AgregarSolicitud />} />
          <Route path="asistencia/buscar" element={<BuscarAsistencia />} />
          <Route path="asistencia/agregar" element={<AgregarAsistencia />} />

          <Route path="solicitud" element={<BuscarSolicitud />} />

          <Route path="configurar/puestos" element={<ConfigurarPuesto />} />
          <Route path="configurar/clientes" element={<ConfigurarCliente />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
