import React, { useState } from "react";
import {
  Button,
  Table,
  TimePicker,
  Form,
  Typography,
  DatePicker,
  Checkbox,
  Radio,
  Input,
  Row,
  Col,
} from "antd";
import moment from "moment";
import "moment/locale/es";
import Empleados from "../empleados/Empleados";
import { useNotification } from "../NotificationContext";
import { auth } from "../../firebase";
import { guardarAsistencia } from "../../servicios/recursosHumanos/servicios"
import { useAuth } from "../Context";

moment.updateLocale("es", {
  week: {
    dow: 1,
  },
});

const { Title } = Typography;
const { TextArea } = Input;

const timeFormat = "HH:mm";

const entrance = [
  0, 1, 2, 3, 4, 5, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];
const eatIn = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23,
];
const eatOut = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 16, 17, 18, 19, 20, 21, 22, 23,
];
const leave = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
const weekendLeave = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 19, 20, 21, 22, 23];

const getWeekDays = (date) => {
  const startOfWeek = moment(date).startOf("isoWeek");
  return Array.from({ length: 6 }).map((_, i) =>
    startOfWeek.clone().add(i, "days")
  );
};

const AgregarAsistencia = () => {
  const [form] = Form.useForm();
  const [weekDates, setWeekDates] = useState(getWeekDays(moment()));
  const [selectedDays, setSelectedDays] = useState([]);
  const [attendanceType, setAttendanceType] = useState({});
  const openNotificationWithIcon = useNotification();
  const [loading, setLoading] = useState(false);
  const { sucursal } = useAuth();

  const handleWeekChange = (date) => {
    if (date) {
      const newWeekDates = getWeekDays(date.$d);
      setWeekDates(newWeekDates);
      setSelectedDays([]);
      setAttendanceType({});
    }
  };

  const handleDaySelection = (day) => {
    setSelectedDays((prev) => {
      if (prev.includes(day)) {
        return prev.filter((d) => d !== day);
      } else {
        return [...prev, day];
      }
    });
  };

  const handleAttendanceTypeChange = (day, type) => {
    setAttendanceType((prev) => ({ ...prev, [day]: type }));
  };

  const handlerForm = async (values) => {
    setLoading(true);
    try {
      const body = transformData(values);
      body['sucursal'] = sucursal;
      const tokenValid = await auth.currentUser.getIdToken(true);
      const response = await guardarAsistencia(tokenValid, body);
      if (response.success) {
        const asistencias = body.asistencias.filter(item => item.asistencia).length;
        const inasistencias = body.asistencias.filter(item => item.asistencia === false).length;

        openNotificationWithIcon(
          "success",
          "Asistencia(s) agregada(s)",
          `${asistencias} asistencias ${inasistencias} inasistencias agregadas.`
        );
        form.resetFields();
      } else {
        openNotificationWithIcon(
          "warning",
          "Algo salio mal",
          response.error.msg ? response.error.msg : response.error
        );
        showErrorMessages(response.errors);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Asistencia(s) no guardada(s)",
        `Intente de nuevo o consulte con su administrador`
      );
      console.error("Error: ", error);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Día",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Seleccionar",
      dataIndex: "select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedDays.includes(record.key)}
          onChange={() => handleDaySelection(record.key)}
        />
      ),
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      render: (_, record) => (
        <Radio.Group
          onChange={(e) =>
            handleAttendanceTypeChange(record.key, e.target.value)
          }
          value={attendanceType[record.key]}
          disabled={!selectedDays.includes(record.key)}
        >
          <Radio value="asistencia">Asistencia</Radio>
          <Radio value="inasistencia">Inasistencia</Radio>
        </Radio.Group>
      ),
    },
    {
      title: "Entrada",
      dataIndex: "entrada",
      key: "entrada",
      render: (_, record) =>
        attendanceType[record.key] === "asistencia" ? (
          <Form.Item
            name={[record.key, "entrada"]}
            rules={[
              {
                required: selectedDays.includes(record.key),
                message: "Por favor selecciona la hora de entrada",
              },
            ]}
          >
            <TimePicker
              format={timeFormat}
              disabledHours={() => entrance}
              hideDisabledOptions
              disabled={!selectedDays.includes(record.key)}
            />
          </Form.Item>
        ) : attendanceType[record.key] === "inasistencia" ? (
          <Form.Item
            name={[record.key, "motivoInasistencia"]}
          >
            <TextArea
              placeholder="Motivo de inasistencia"
              disabled={!selectedDays.includes(record.key)}
            />
          </Form.Item>
        ) : null,
    },
    {
      title: "Salida a comida",
      dataIndex: "salidaComida",
      key: "salidaComida",
      render: (_, record) =>
        attendanceType[record.key] === "asistencia" && !record.day.includes("sábado")  ? (
          <Form.Item
            name={[record.key, "salidaComida"]}
            rules={[
              {
                required: selectedDays.includes(record.key),
                message: "Por favor selecciona la hora de salida a comida",
              },
            ]}
          >
            <TimePicker
              format={timeFormat}
              disabledHours={() => eatOut}
              hideDisabledOptions
              disabled={!selectedDays.includes(record.key)}
            />
          </Form.Item>
        ) : null,
    },
    {
      title: "Entrada de comida",
      dataIndex: "entradaComida",
      key: "entradaComida",
      render: (_, record) =>
        attendanceType[record.key] === "asistencia" && !record.day.includes("sábado") ? (
          <Form.Item
            name={[record.key, "entradaComida"]}
            rules={[
              {
                required: selectedDays.includes(record.key),
                message: "Por favor selecciona la hora de entrada de comida",
              },
            ]}
          >
            <TimePicker
              format={timeFormat}
              disabledHours={() => eatOut}
              hideDisabledOptions
              disabled={!selectedDays.includes(record.key)}
            />
          </Form.Item>
        ) : null,
    },
    {
      title: "Salida",
      dataIndex: "salida",
      key: "salida",
      render: (_, record) =>
        attendanceType[record.key] === "asistencia" ? (
          <Form.Item
            name={[record.key, "salida"]}
            rules={[
              {
                required: selectedDays.includes(record.key),
                message: "Por favor selecciona la hora de salida",
              },
            ]}
          >
            <TimePicker
              format={timeFormat}
              disabledHours={() => record.day.includes("sábado") ? weekendLeave : leave}
              hideDisabledOptions
              disabled={!selectedDays.includes(record.key)}
            />
          </Form.Item>
        ) : null,
    },
  ];

  const dataSource = weekDates.map((date, index) => ({
    key: index,
    day: `${date.format("dddd")} ${date.date()} de ${date.format("MMMM")}`,
    entrada: null,
    salidaComida: null,
    entradaComida: null,
    salida: null,
  }));

  const showErrorMessages = (errorObject) => {
    if (!errorObject || !Array.isArray(errorObject)) {
        console.error('Invalid error object structure');
        return;
    }

    errorObject.forEach((error) => {
        const field = document.getElementById(error.field);
        if (field) {
            const errorMessage = document.createElement('div');
            errorMessage.className = 'error-message'; // Puedes agregar estilos CSS para esta clase
            errorMessage.innerText = error.error;
            
            // Limpiar errores anteriores si existen
            const existingError = field.nextSibling;
            if (existingError && existingError.className === 'error-message') {
                existingError.remove();
            }

            field.parentNode.insertBefore(errorMessage, field.nextSibling);
        } else {
            console.warn(`Field with id ${error.field} not found`);
        }
    });
};

  const disabledDate = (current) => {
    return (
      current < moment().subtract(1, "month") ||
      current > moment() ||
      current.day() === 0
    );
  };

  const transformData = (data) => {
    const asistencias = selectedDays.map((dayIndex) => {
      const dayData = data[dayIndex];

      if (attendanceType[dayIndex] === "asistencia" && dayIndex < 5) {
        return {
          asistencia: true,
          entrada:
            (dayData.entrada.$H < 10
              ? "0" + dayData.entrada.$H
              : dayData.entrada.$H) +
            ":" +
            (dayData.entrada.$m < 10
              ? "0" + dayData.entrada.$m
              : dayData.entrada.$m),
          salidaComida:
            (dayData.salidaComida.$H < 10
              ? "0" + dayData.salidaComida.$H
              : dayData.salidaComida.$H) +
            ":" +
            (dayData.salidaComida.$m < 10
              ? "0" + dayData.salidaComida.$m
              : dayData.salidaComida.$m),
          entradaComida:
            (dayData.entradaComida.$H === 1
              ? "0" + dayData.entradaComida.$H
              : dayData.entradaComida.$H) +
            ":" +
            (dayData.entradaComida.$m < 10
              ? "0" + dayData.entradaComida.$m
              : dayData.entradaComida.$m),
          salida:
            (dayData.salida.$H < 10
              ? "0" + dayData.salida.$H
              : dayData.salida.$H) +
            ":" +
            (dayData.salida.$m < 10
              ? "0" + dayData.salida.$m
              : dayData.salida.$m),
          diaSemana: weekDates[dayIndex].day(),
        };
      } else if (attendanceType[dayIndex] === "asistencia" && dayIndex === 5) {
        return {
          asistencia: true,
          entrada:
            (dayData.entrada.$H < 10
              ? "0" + dayData.entrada.$H
              : dayData.entrada.$H) +
            ":" +
            (dayData.entrada.$m < 10
              ? "0" + dayData.entrada.$m
              : dayData.entrada.$m),
          salida:
            (dayData.salida.$H < 10
              ? "0" + dayData.salida.$H
              : dayData.salida.$H) +
            ":" +
            (dayData.salida.$m < 10
              ? "0" + dayData.salida.$m
              : dayData.salida.$m),
          diaSemana: weekDates[dayIndex].day(),
        };
      } else if (attendanceType[dayIndex] === "inasistencia") {
        return {
          diaSemana: weekDates[dayIndex].day(),
          asistencia: false,
          motivoInasistencia: dayData.motivoInasistencia,
        };
      }

      return null;
    });

    const response = {
      anio: moment(weekDates[0]).year(),
      semana: moment(weekDates[0]).week(),
      empleado: data.empleado,
      asistencias: asistencias.filter(Boolean),
    };

    return response;
  };

  return (
    <div>
      <Title level={3} className="ccg-center">Agregar asistencia</Title>
      <Form form={form} layout="vertical" onFinish={handlerForm}>
        <Row>
          <Col xs={8}>
            <Form.Item
              name="semana"
              label="Semana"
              rules={[
                { required: true, message: "Por favor selecciona una semana" },
              ]}
            >
              <DatePicker
                picker="week"
                format="wo [semana de] YYYY"
                disabledDate={disabledDate}
                onChange={handleWeekChange}
              />
            </Form.Item>
          </Col>
          <Col xs={16}>
            <Empleados search />
          </Col>
        </Row>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowKey="day"
        />
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar asistencias
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AgregarAsistencia;
