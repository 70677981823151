import React, { useState, useEffect, useRef } from "react";
import { List, Spin, Input, Button, Form, Row, Col, message } from "antd";
import Title from "antd/es/typography/Title";
import { auth, firestore } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { sendAnswer } from "../../servicios/solicitudes/servicios";
import useNotification from "antd/es/notification/useNotification";

const { TextArea } = Input;

const ChatComponent = ({ solicitudInfo }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const messagesEndRef = useRef(null);
  const openNotificationWithIcon = useNotification();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const saveAnswer = async (info) => {
    setProcessing(true);
    try {
      const body = {
        mensaje: info.respuesta,
        sucursal: "Centro",
        folio: solicitudInfo.folio,
      };

      const validedToken = await auth.currentUser.getIdToken(true);
      const response = await sendAnswer(validedToken, body);
      if (response && response.success) {
        openNotificationWithIcon(
          "success",
          "Respuesta enviada"
        );
      } else {
        openNotificationWithIcon(
          "warning",
          "Error al momento de mandar respuesta, intente más tarde",
          response.error.msg ? response.error.msg : response.error
        );
      }
    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Error",
        `Se presento un error al enviar respuesta, intente más tarde.`
      );
    }
    setProcessing(false);
  };

  useEffect(() => {
    if (solicitudInfo) {
      const docRef = doc(firestore, "sucursal/Centro/solicitud", solicitudInfo.folio);
      // Set up subscription
      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const solicitudData = docSnapshot.data();
          setMessages(solicitudData.mensajes || []);
          setLoading(false);
        } else {
          console.log("No such document!");
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [solicitudInfo]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <Title level={2} className="ccg-center">
            Mensajes
          </Title>
        </Col>
      </Row>
      <Row style={{paddingBottom: '20px'}}>
        <Col xs={24} md={12}>
          Nombre: <b>{solicitudInfo.nombre}</b>
        </Col>
        <Col xs={24} md={12}>
          Folio: <b>{solicitudInfo.folio}</b>
        </Col>
        <Col xs={24} md={12}>
          Teléfono: <b>{solicitudInfo.telefono}</b>
        </Col>
        <Col xs={24} md={12}>
          Origen: <b>{solicitudInfo.origen}</b>
        </Col>
      </Row>
      <div className="ccg-chat-solicitudes">
        {loading && <Spin tip="Cargando mensajes..." />}
        <List
          dataSource={messages}
          renderItem={(item) => (
            <List.Item>
              <div style={{ width: "100%" }}>
                <div style={{ textAlign: "left", borderRadius: "10px" }}>
                  <List.Item.Meta
                    title={item.creacion}
                    description={item.mensaje}
                  />
                </div>
                {item.respuesta && (
                  <div
                    style={{
                      textAlign: "right",
                      backgroundColor: "linen",
                      padding: "8px",
                      borderRadius: "10px",
                      marginTop: "8px",
                    }}
                  >
                    <List.Item.Meta
                      title={item.respuesta_creacion}
                      description={item.respuesta}
                    />
                  </div>
                )}
              </div>
            </List.Item>
          )}
        />
        <div ref={messagesEndRef} />
      </div>
      <Form onFinish={saveAnswer} layout="vertical" style={{ bottom: 0 }}>
        <Row gutter={24}>
          <Col xs={18}>
            <Form.Item
              name="respuesta"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese una respuesta",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Escribe una respuesta."
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={processing}
            >
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ChatComponent;
