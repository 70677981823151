import React, { useEffect, useState } from "react";
import "../../App.css";
import { Input, Form, Typography, message, Spin, Row, Col } from "antd";
import { firestore } from "../../firebase";
import Empleado from "./Empleado";
import { useAuth } from "../Context";
import { onSnapshot, collection } from "firebase/firestore";

const { Title } = Typography;

const BuscarEmpleado = () => {
  const [searchText, setSearchText] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const { sucursal } = useAuth();

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(
      collection(firestore, `/sucursal/${sucursal}/empleado`),
      (snapshot) => {
        const employeeData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeeData);
      }
    );

    setLoading(false);
    return () => unsubscribe();
  }, [sucursal]);

  const filteredEmployees = employees.filter(
    (employee) =>
      employee.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
      employee.ap_paterno.toLowerCase().includes(searchText.toLowerCase()) ||
      employee.puesto.toLowerCase().includes(searchText.toLowerCase()) ||
      employee.celular.includes(searchText)
  );

  return (
    <>
      <Title level={3} className="ccg-center">
        Información de Empleados
      </Title>

      <Form.Item
        label="Buscar"
        name="search"
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 8,
        }}
        rules={[
          {
            required: true,
            message: "Por favor ingresa un valor.",
          },
        ]}
      >
        <Input
          placeholder="Buscar..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          size="large"
        />
      </Form.Item>

      {loading ? (
        <>
          <Spin></Spin>
        </>
      ) : (
        <>
          <Row gutter={8}>
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee) => (
                <Col xs={12} md={8} lg={4}>
                  <Empleado key={employee.id} employee={employee} />
                </Col>
              ))
            ) : (
              <p>No se encontró información</p>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default BuscarEmpleado;
