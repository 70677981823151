import { Form, Select } from "antd";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { useAuth } from "../Context";

const Sucursales = ({ onSucursalChange }) => {
  const { Option } = Select;
  const [data, setData] = useState([]);
  const { sucursal } = useAuth();

  useEffect(() => {
    const q = collection(firestore, `sucursal/${sucursal}/cliente`);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clientesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(clientesData.filter(item => item.estatus === 'Activo'));
    });

    return () => unsubscribe();
  }, [sucursal]);

  return (
    <Form.Item
      label="Sucursal"
      name="sucursal"
      rules={[
        {
          required: true,
          message: "Por favor selecciona una sucursal",
        },
      ]}
    >
      <Select defaultValue={"0"} onChange={onSucursalChange}>
        <Option value="0" disabled>
          Selecciona un cliente
        </Option>
        {data.map((item) => (
          <Option key={item.razon_social}>{item.razon_social}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Sucursales;
