import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Button, Form, Input, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import Title from 'antd/es/typography/Title';
import { useAuth } from "./Context";

const localizer = momentLocalizer(moment);
moment.locale('es');

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [form] = Form.useForm();
  const now = new Date();
  const minTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 6, 0, 0); // 6:00 AM
  const maxTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22, 0, 0); // 10:00 PM
  const { permisos } = useAuth();


  useEffect(() => {
    // Simula la carga de eventos desde una base de datos
    const initialEvents = [
      {
        id: uuidv4(),
        title: 'Evento 1',
        start: new Date(),
        end: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      {
        id: uuidv4(),
        title: 'Evento 2',
        start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        end: new Date(new Date().getTime() + 25 * 60 * 60 * 1000),
      },
    ];
    setEvents(initialEvents);
    console.log('permisos bienvenida: ', permisos);
  }, [permisos]);

  const handleAddEvent = () => {
    form.validateFields().then(values => {
      const newEvent = {
        id: uuidv4(),
        title: values.title,
        start: values.start.toDate(),
        end: values.end.toDate(),
      };
      setEvents([...events, newEvent]);
      // Aquí deberías enviar este evento a tu base de datos y a Google Calendar
      form.resetFields();
      setIsModalVisible(false);
    });
  };

  const handleSelectSlot = (slotInfo) => {
    form.setFieldsValue({
      start: dayjs(slotInfo.start),
      end: dayjs(slotInfo.end)
    });
    setIsModalVisible(true);
  };

  const handleEventClick = (event) => {
    setCurrentEvent(event);
    setIsDetailModalVisible(true);
  };

  return (
    <>
      <Title level={3} className="ccg-center">Agenda Personal</Title>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleEventClick}
        defaultView="week"
        messages={{
          week: 'Semana',
          work_week: 'Semana laboral',
          day: 'Día',
          month: 'Mes',
          previous: 'Atrás',
          next: 'Siguiente',
          today: 'Hoy',
          agenda: 'Agenda',
          noEventsInRange: 'No hay eventos en este rango',
          showMore: total => `+ Ver más (${total})`
        }}
        min={minTime}
        max={maxTime}
      />
      <Modal
        title="Agregar Evento"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddEvent}>
            Agregar
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="title" label="Título" rules={[{ required: true, message: 'Por favor ingrese un título' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="start" label="Inicio" rules={[{ required: true, message: 'Por favor seleccione una fecha de inicio' }]}>
            <DatePicker showTime locale={locale} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="end" label="Fin" rules={[{ required: true, message: 'Por favor seleccione una fecha de fin' }]}>
            <DatePicker showTime locale={locale} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Detalles del Evento"
        open={isDetailModalVisible}
        onCancel={() => setIsDetailModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsDetailModalVisible(false)}>
            Cerrar
          </Button>,
        ]}
      >
        {currentEvent && (
          <div>
            <p><strong>Título:</strong> {currentEvent.title}</p>
            <p><strong>Inicio:</strong> {moment(currentEvent.start).format('LLLL')}</p>
            <p><strong>Fin:</strong> {moment(currentEvent.end).format('LLLL')}</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default MyCalendar;
