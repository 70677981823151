import React, { useEffect, useState } from "react";
import "../../App.css";
import DataTable from "react-data-table-component";
import { Button, Form, Input, message, Menu, Dropdown } from "antd";
import Title from "antd/es/typography/Title";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { auth } from "../../firebase";

const BuscarVehiculo = () => {
  const [searchText, setSearchText] = useState("");
  const [pending, setPending] = React.useState(true);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [datalayer, setDatalayer] = React.useState([]);

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Sucursal",
      selector: (row) => row.branch,
      sortable: true,
    },
    {
      name: "Puesto",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Correo",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estatus = 'Activo',
      sortable: true,
    },
    {
      name: "Acciones",
      cell: (row) => (
        <Dropdown overlay={() => menu(row)} trigger={["click"]}>
          <Button type="default" icon={<EllipsisOutlined />}></Button>
        </Dropdown>
      ),
      sortable: false,
      width: "100px",
    },
  ];

  const handleEdit = (record) => {
    // onEdit(record.id);
    message.success("Empleado actualizado exitosamente: ");
  };

  const handleDisable = async (record, status) => {
    console.log("record: ", record);
    try {
      const body = {};
      body["status"] = status ? "Activo" : "Inactivo";
      body["uid"] = record.id;
      const tokenValid = await auth.currentUser.getIdToken(true);
      // const response = await disableUser(tokenValid, body);
      const response = true;
      if (response) message.info("Empleado desactivado exitosamente.");
      else message.warning("No se pudo desactivar el usuario");
    } catch (error) {
      console.error("Error al deshabilitar el usuario con UID:", error);
    }
  };

  const handleMenuClick = (action, record) => {
    if (action === "edit") {
      handleEdit(record);
    } else if (action === "disable") {
      handleDisable(record, false);
    } else if (action === "active") {
      handleDisable(record, true);
    }
  };

  const menu = (record) => (
    <Menu onClick={(e) => handleMenuClick(e.key, record)}>
      {/* <Menu.Item key="edit" icon={<EditOutlined />}>
          Editar
        </Menu.Item> */}
      {record.estatus === "Activo" ? (
        <>
          <Menu.Item key="disable" icon={<EyeOutlined />}>
            Deshabilitar
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="active" icon={<EyeOutlined />}>
            Activar
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const filteredData = datalayer.filter(
    (item) =>
      String(item.name).toLowerCase().includes(searchText.toLowerCase()) ||
      String(item.position).toLowerCase().includes(searchText.toLowerCase()) ||
      String(item.email).toLowerCase().includes(searchText.toLowerCase()) ||
      String(item.estatus).toLowerCase().includes(searchText.toLowerCase())
  );

  const conditionalRowStyles = [
    {
      when: (row) => true,
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDeleteRows = () => {
    if (
      window.confirm(`¿Estas seguro?:\r ${selectedRows.map((r) => r.name)}?`)
    ) {
      setToggleCleared(!toggleCleared);
      setSelectedRows([]);
    }
  };

  function convertArrayOfObjectsToCSV(rows) {
    const header = datalayer[0];
    delete header.id;

    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(header);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    rows.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    if (array.length === 0) {
      message.info("Debe seleccionar los empleados que desea exportar");
      return;
    }
    array = array.map((row) => {
      const dataWithoutId = { ...row };
      delete dataWithoutId.id;
      return dataWithoutId;
    });

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Empleados.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ selectedRows, onExport }) => (
    <Button
      style={{ zIndex: 100, position: "absolute" }}
      onClick={() => onExport(selectedRows)}
    >
      Export
    </Button>
  );

  const actionsMemo = React.useMemo(
    () => (
      <Export
        selectedRows={selectedRows}
        onExport={(selectedRows) => downloadCSV(selectedRows)}
      />
    ),
    [selectedRows]
  );

  useEffect(() => {
    const loadData = async () => {
      const tokenValid = await auth.currentUser.getIdToken(true);
      // const users = await getUsers(tokenValid);
      const users = {info: [{
        "id": 1,
        "name": "Carine",
        "email": "cjacquest0@java.com",
        "position": "Database Administrator IV",
        "branch": "SHISEIDO SHEER MATIFYING COMPACT (REFILL)",
        "phone": "2998441144"
      }, {
        "id": 2,
        "name": "Warde",
        "email": "wbarok1@who.int",
        "position": "VP Product Management",
        "branch": "Desmopressin Acetate",
        "phone": "4171788747"
      }, {
        "id": 3,
        "name": "Rolando",
        "email": "rmccolm2@nasa.gov",
        "position": "Nurse Practicioner",
        "branch": "ISAKNOX AGELESS SERUM BLEMISH BALM",
        "phone": "4343150204"
      }, {
        "id": 4,
        "name": "Kara",
        "email": "kragate3@lycos.com",
        "position": "Administrative Assistant I",
        "branch": "Scent Killer Anti-Perspirant and Deodorant",
        "phone": "6356884829"
      }, {
        "id": 5,
        "name": "Jordain",
        "email": "jtrangmar4@chicagotribune.com",
        "position": "Librarian",
        "branch": "Cold Spot",
        "phone": "3765553312"
      }, {
        "id": 6,
        "name": "Mort",
        "email": "mginley5@patch.com",
        "position": "Senior Financial Analyst",
        "branch": "Fosinopril Sodium",
        "phone": "6687073822"
      }, {
        "id": 7,
        "name": "Jillie",
        "email": "jdaspar6@blogger.com",
        "position": "Electrical Engineer",
        "branch": "Tussin Chest Congestion",
        "phone": "8738367592"
      }, {
        "id": 8,
        "name": "Pooh",
        "email": "plumly7@paypal.com",
        "position": "Programmer III",
        "branch": "Triamterene and Hydrochlorothiazide",
        "phone": "7987174728"
      }, {
        "id": 9,
        "name": "Niels",
        "email": "nbrooker8@blogs.com",
        "position": "Administrative Assistant III",
        "branch": "Acne Skin eruptions",
        "phone": "7386122086"
      }, {
        "id": 10,
        "name": "Any",
        "email": "aallcorn9@oakley.com",
        "position": "Health Coach III",
        "branch": "American Cockroach",
        "phone": "3472593410"
      }, {
        "id": 11,
        "name": "Madelena",
        "email": "mglassarda@gizmodo.com",
        "position": "Database Administrator III",
        "branch": "JOHNSON GRASS POLLEN",
        "phone": "7612046906"
      }, {
        "id": 12,
        "name": "Vanessa",
        "email": "vlakelandb@uiuc.edu",
        "position": "Software Test Engineer III",
        "branch": "Formica Apis",
        "phone": "9373910460"
      }, {
        "id": 13,
        "name": "Hubert",
        "email": "hentwislec@blogtalkradio.com",
        "position": "Geological Engineer",
        "branch": "Penicillin V Potassium",
        "phone": "3284314380"
      }, {
        "id": 14,
        "name": "Hana",
        "email": "hhutchinsd@fastcompany.com",
        "position": "General Manager",
        "branch": "Lansoprazole",
        "phone": "7612843443"
      }, {
        "id": 15,
        "name": "Mathew",
        "email": "msandelande@yahoo.co.jp",
        "position": "Financial Advisor",
        "branch": "AVINZA",
        "phone": "9137779928"
      }, {
        "id": 16,
        "name": "Lenee",
        "email": "lconechief@nasa.gov",
        "position": "Graphic Designer",
        "branch": "CEFAZOLIN",
        "phone": "5787217553"
      }, {
        "id": 17,
        "name": "Sapphire",
        "email": "sspellecyg@angelfire.com",
        "position": "Junior Executive",
        "branch": "Nitrogen",
        "phone": "2136462296"
      }, {
        "id": 18,
        "name": "Karrah",
        "email": "ksiberryh@illinois.edu",
        "position": "Safety Technician I",
        "branch": "Paclitaxel",
        "phone": "8456170569"
      }, {
        "id": 19,
        "name": "Billie",
        "email": "bbeauchampi@cmu.edu",
        "position": "Senior Quality Engineer",
        "branch": "Cleanoz",
        "phone": "4753816880"
      }, {
        "id": 20,
        "name": "Randie",
        "email": "rstonehewerj@nsw.gov.au",
        "position": "Professor",
        "branch": "Tahitian Noni",
        "phone": "7361453209"
      }, {
        "id": 21,
        "name": "Elnore",
        "email": "enettik@ow.ly",
        "position": "Civil Engineer",
        "branch": "PredniSONE",
        "phone": "5563491625"
      }, {
        "id": 22,
        "name": "Raphaela",
        "email": "rlemanul@shinystat.com",
        "position": "Mechanical Systems Engineer",
        "branch": "ARANESP",
        "phone": "2842176780"
      }, {
        "id": 23,
        "name": "Mallorie",
        "email": "mharkinm@psu.edu",
        "position": "Pharmacist",
        "branch": "NITRO-BID",
        "phone": "1667239537"
      }, {
        "id": 24,
        "name": "Briny",
        "email": "bbridaln@wikispaces.com",
        "position": "VP Marketing",
        "branch": "Bisoprolol Fumarate",
        "phone": "6697996576"
      }, {
        "id": 25,
        "name": "Devin",
        "email": "dparagreeno@geocities.jp",
        "position": "Nurse Practicioner",
        "branch": "Quinapril",
        "phone": "9296098073"
      }, {
        "id": 26,
        "name": "Alejoa",
        "email": "aalvaradop@examiner.com",
        "position": "Payment Adjustment Coordinator",
        "branch": "Night Time Original",
        "phone": "3821831485"
      }, {
        "id": 27,
        "name": "Ivonne",
        "email": "iscobleq@livejournal.com",
        "position": "Analyst Programmer",
        "branch": "lice",
        "phone": "4897039415"
      }, {
        "id": 28,
        "name": "Lenette",
        "email": "laxtonner@howstuffworks.com",
        "position": "Database Administrator II",
        "branch": "Amlodipine Besylate",
        "phone": "8534201578"
      }, {
        "id": 29,
        "name": "Cross",
        "email": "cagnolos@sbwire.com",
        "position": "Electrical Engineer",
        "branch": "Propranolol Hydrochloride",
        "phone": "8969860945"
      }, {
        "id": 30,
        "name": "Holt",
        "email": "hskillt@purevolume.com",
        "position": "VP Accounting",
        "branch": "Losartan Potassium",
        "phone": "1673012580"
      }, {
        "id": 31,
        "name": "Nina",
        "email": "nmacgrayu@squidoo.com",
        "position": "Quality Engineer",
        "branch": "Secret Clinical Strength",
        "phone": "8796953986"
      }, {
        "id": 32,
        "name": "Loralie",
        "email": "lbroadeyv@gnu.org",
        "position": "Computer Systems Analyst III",
        "branch": "Aveeno Anti Itch Concentrated",
        "phone": "9396496883"
      }, {
        "id": 33,
        "name": "Danika",
        "email": "dmaccrackenw@spiegel.de",
        "position": "Registered Nurse",
        "branch": "Quality Choice Povidone Iodine",
        "phone": "9105268343"
      }, {
        "id": 34,
        "name": "Vonni",
        "email": "vstangoex@1und1.de",
        "position": "Help Desk Operator",
        "branch": "Neosporin Plus Pain Relief Maximum Strength",
        "phone": "8847597123"
      }, {
        "id": 35,
        "name": "Krystal",
        "email": "kericsseny@ask.com",
        "position": "Senior Quality Engineer",
        "branch": "Johnson Grass",
        "phone": "3195257750"
      }, {
        "id": 36,
        "name": "Merrile",
        "email": "mmaddernz@lycos.com",
        "position": "Geological Engineer",
        "branch": "Fluocinonide",
        "phone": "2426607888"
      }, {
        "id": 37,
        "name": "Magdalene",
        "email": "mchampe10@altervista.org",
        "position": "Occupational Therapist",
        "branch": "Ziprasidone hydrochloride",
        "phone": "2606807188"
      }, {
        "id": 38,
        "name": "Ardis",
        "email": "aeliasson11@ebay.co.uk",
        "position": "Dental Hygienist",
        "branch": "Hy5",
        "phone": "7201446944"
      }, {
        "id": 39,
        "name": "Olly",
        "email": "obradden12@amazonaws.com",
        "position": "Accountant III",
        "branch": "Invirase",
        "phone": "4855153633"
      }, {
        "id": 40,
        "name": "Corabel",
        "email": "cbonner13@clickbank.net",
        "position": "Developer I",
        "branch": "Clobex",
        "phone": "9959377668"
      }, {
        "id": 41,
        "name": "Dolorita",
        "email": "dbenn14@answers.com",
        "position": "VP Marketing",
        "branch": "OXYCODONE AND ACETAMINOPHEN",
        "phone": "7455813963"
      }]};
      setDatalayer(users.info);
      setPending(false);
    };
    loadData();
  }, []);

  return (
    <>
      <Title level={3} style={{ textAlign: "center" }}>
        Información de Vehículos
      </Title>

      <Form.Item
        label="Buscar"
        name="search"
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 8,
        }}
        rules={[
          {
            required: true,
            message: "Por favor ingresa un valor.",
          },
        ]}
      >
        <Input
          placeholder="Buscar..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          size="large"
        />
      </Form.Item>
      <DataTable
        className="ttbl"
        noDataComponent={"No se encontro información"}
        columns={columns}
        data={filteredData}
        progressPending={pending}
        progressComponent={<Title level={4}>Cargando datos...</Title>}
        conditionalRowStyles={conditionalRowStyles}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página:",
          rangeSeparatorText: "de",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "Todos",
        }}
        striped
        highlightOnHover
        responsive
        scrollY={400}
        defaultSortField="name"
        defaultSortAsc
        selectableRows
        contextMessage={{
          singular: "empleado",
          plural: "empleados",
          message: "seleccionado(s)",
        }}
        onSelectedRowsChange={handleRowSelected}
        actions={actionsMemo}
        clearSelectedRows={toggleCleared}
      />
    </>
  );
};

export default BuscarVehiculo;
