import React, { Suspense, useEffect, useState } from "react";
import {
  Tree,
  Row,
  Col,
  Spin,
  TreeSelect,
  Transfer,
  message,
  Input,
  Button,
} from "antd";
import Sucursales from "../sucursales/Sucursales";
import Title from "antd/es/typography/Title";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { auth, firestore } from "../../firebase";
import { useNotification } from "../NotificationContext";
import {
  crearArea,
  crearPuesto,
  eliminarArea,
  eliminarPuesto,
  agregarPermisos,
} from "../../servicios/puestos/servicios";

const ConfigurarPuesto = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [permisos, setPermisos] = useState([]);
  const [defaultPermisos, setDefaultPermisos] = useState([]);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const [newArea, setNewArea] = useState("");
  const openNotificationWithIcon = useNotification();

  useEffect(() => {
    if (!selectedSucursal) return;

    const fetchDefaultPermisos = async () => {
      const docRef = doc(
        firestore,
        `sucursal/${selectedSucursal}/configuracion`,
        "permiso"
      );
      onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const permisosData = docSnapshot.data();
          const permisosActivos = getTruePermissions(permisosData);

          setDefaultPermisos(permisosActivos);
        } else {
          console.log("No such document!");
        }
      });
    };

    const subscribeToAreas = (puestoId) => {
      const areasCollectionRef = collection(
        firestore,
        `sucursal/${selectedSucursal}/puesto/${puestoId}/areas`
      );
      return onSnapshot(areasCollectionRef, (querySnapshot) => {
        const updatedAreas = [];
        querySnapshot.forEach((docSnapshot) => {
          updatedAreas.push({ nombre: docSnapshot.id, ...docSnapshot.data() });
        });

        setDepartments((prevDepartments) =>
          prevDepartments.map((department) =>
            department.nombre === puestoId
              ? { ...department, areas: updatedAreas }
              : department
          )
        );
      });
    };

    const collectionRef = collection(
      firestore,
      `sucursal/${selectedSucursal}/puesto`
    );
    const unsubscribeFromPuestos = onSnapshot(
      collectionRef,
      (querySnapshot) => {
        const updatedData = [];
        const unsubscribes = [];

        querySnapshot.forEach((docSnapshot) => {
          const puestoData = {
            nombre: docSnapshot.id,
            ...docSnapshot.data(),
            areas: [],
          };
          if (
            puestoData.nombre !== "Director General" &&
            puestoData.nombre !== "Gerente General"
          ) {
            updatedData.push(puestoData);

            const unsubscribeFromAreas = subscribeToAreas(docSnapshot.id);
            unsubscribes.push(unsubscribeFromAreas);
          }
        });

        setDepartments(
          updatedData.sort((a, b) => a.nombre.localeCompare(b.nombre))
        );

        return () => {
          unsubscribes.forEach((unsubscribe) => unsubscribe());
        };
      }
    );

    fetchDefaultPermisos(); // Llamar directamente aquí
    return () => {
      unsubscribeFromPuestos();
    };
  }, [selectedSucursal]);

  useEffect(() => {
    if (
      (!selectedArea || selectedArea === undefined) &&
      (!selectedDepartment || selectedDepartment === undefined)
    )
      return;

    const permisosPath = selectedArea.includes("-") ? true : false;

    const collectionPath = permisosPath
      ? `sucursal/${selectedSucursal}/puesto/${selectedDepartment}/areas/${
          selectedArea.split("-")[1]
        }/configuracion/permisos`
      : `sucursal/${selectedSucursal}/puesto/${selectedDepartment}/configuracion/permisos`;

    const unsubscribeFromPermisos = onSnapshot(
      doc(firestore, collectionPath),
      (querySnapshot) => {
        if (querySnapshot.exists && querySnapshot.data() !== undefined) {
          const updatedPermisos = [querySnapshot.data()];
          console.log('permisos actuales: ', updatedPermisos);
          setPermisos(updatedPermisos[0]);
        } else setPermisos([]);
      }
    );

    return () => {
      unsubscribeFromPermisos();
    };
  }, [selectedArea, selectedDepartment, selectedSucursal]);

  const getTruePermissions = (obj) => {
    let result = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value === true) {
        result[key] = true;
      } else if (typeof value === "object" && value !== null) {
        const nestedResult = getTruePermissions(value);
        if (Object.keys(nestedResult).length > 0) {
          result[key] = nestedResult;
        }
      }
    }
    return result;
  };

  const handleSucursalChange = (value) => {
    setSelectedSucursal(value);
    setSelectedDepartment(null);
    setSelectedArea(null);
    setPermisos([]);
  };

  const handleSelect = (selectedKeys, { node }) => {
    const [selectedKey] = selectedKeys;
    if (selectedKey && selectedKey !== undefined) {
      const isArea = node.isLeaf;
      setSelectedDepartment(isArea ? node.props.parentId : selectedKey);
      setSelectedArea(isArea ? selectedKey : selectedKeys);
    }
  };

  const handleAddDepartment = async () => {
    try {
      if (!newDepartmentName) {
        message.error("Ingrese el nombre del puesto");
        return;
      }

      const body = {
        sucursal: selectedSucursal,
        nombre: newDepartmentName,
      };

      const validadedToken = await auth.currentUser.getIdToken(true);
      const response = await crearPuesto(validadedToken, body);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Puesto Guardado",
          "Se guardo el puesto " + newDepartmentName
        );
        setNewDepartmentName("");
      } else
        openNotificationWithIcon(
          "warning",
          "Algo salio mal",
          response.error.msg ? response.error.msg : response.error
        );
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error",
        `El puesto ${newDepartmentName} no se guardo.`
      );
      console.error("Error: ", error);
    }
  };

  const handleAddArea = async () => {
    try {
      if (!selectedDepartment || !newArea) {
        message.error("Debes seleccionar un puesto.");
        return;
      }

      const dpto = selectedDepartment.includes("-")
        ? selectedDepartment.split("-")[0]
        : selectedDepartment;

      const body = {
        sucursal: selectedSucursal,
        puesto: dpto,
        nombre: newArea,
      };

      const validadedToken = await auth.currentUser.getIdToken(true);
      const response = await crearArea(validadedToken, body);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Área Guardada",
          "Se guardo el área: " + newArea + " al puesto " + dpto
        );
        setNewArea("");
      } else
        openNotificationWithIcon(
          "warning",
          "Algo salio mal",
          response.error.msg ? response.error.msg : response.error
        );
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error",
        `La área ${newArea} no se guardo.`
      );
      console.error("Error: ", error);
    }
  };

  const handleDeleteDepartment = async (puesto) => {
    try {
      const body = {
        sucursal: selectedSucursal,
        nombre: puesto,
      };

      const validadedToken = await auth.currentUser.getIdToken(true);
      const response = await eliminarPuesto(validadedToken, body);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Puesto eliminado",
          "Se eliminó el puesto " + puesto
        );
        setNewDepartmentName("");
      } else
        openNotificationWithIcon(
          "warning",
          "Algo salio mal",
          response.error.msg ? response.error.msg : response.error
        );
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error",
        `El puesto ${puesto} no se eliminó.`
      );
      console.error("Error: ", error);
    }
  };

  const handleDeleteArea = async (puesto, area) => {
    try {
      const body = {
        sucursal: selectedSucursal,
        puesto: puesto,
        nombre: area,
      };

      const validadedToken = await auth.currentUser.getIdToken(true);
      const response = await eliminarArea(validadedToken, body);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Área eliminada",
          "Se eliminó la área " + area
        );
        setNewDepartmentName("");
      } else
        openNotificationWithIcon(
          "warning",
          "Algo salio mal",
          response.error.msg ? response.error.msg : response.error
        );
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error",
        `El área ${area} no se eliminó.`
      );
      console.error("Error: ", error);
    }
  };

  const renderTreeNodes = (data) => {
    return data.map((item) => (
      <Tree.TreeNode
        title={
          <span>
            {item.nombre}
            {item.deletable && (
              <Button
                type="link"
                size="small"
                onClick={() => handleDeleteDepartment(item.nombre)}
                style={{ marginLeft: 10 }}
              >
                Eliminar
              </Button>
            )}
          </span>
        }
        key={item.nombre}
        value={item.nombre}
        parentId={item.nombre}
      >
        {item.areas &&
          item.areas.map((area) => (
            <Tree.TreeNode
              title={
                <span>
                  {area.nombre}
                  {area.deletable && (
                    <Button
                      type="link"
                      size="small"
                      onClick={() => handleDeleteArea(item.nombre, area.nombre)}
                      style={{ marginLeft: 10 }}
                    >
                      Eliminar
                    </Button>
                  )}
                </span>
              }
              key={item.nombre + "-" + area.nombre}
              value={area.nombre}
              parentId={item.nombre}
              isLeaf
            />
          ))}
      </Tree.TreeNode>
    ));
  };

  const handleTransferChange = async (targetKeys, direction) => {
    const permisos = transformPermisos(targetKeys);
    console.log("permisos: ", permisos);
    const area = selectedArea.includes("-") ? selectedArea.split("-")[1] : null;
    const body = {
      sucursal: selectedSucursal,
      puesto: selectedDepartment,
      area: area,
      permisos,
    };

    const msg =
      body.area !== null
        ? ` al área ${body.area} del puesto ${body.puesto}.`
        : ` al puesto ${body.puesto}`;
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await agregarPermisos(token, body);
      if (response.success)
        openNotificationWithIcon(
          "success",
          "Información Guardada",
          "Permisos agregados " + msg
        );
      else
        openNotificationWithIcon(
          "warning",
          "Algo salió mal",
          "No se agregaron los permisos, intente más tarde."
        );
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Hubo un problema, intente más tarde."
      );
      console.error("Error: ", error);
    }
  };

  const transformPermisos = (permisos) => {
    const permisosObj = {};
  
    permisos.forEach((permiso) => {
      const parts = permiso.split("-");
  
      if (parts.length === 1) {
        permisosObj[parts[0]] = true;
      } else if (parts.length === 2) {
        const [mainPermiso, subPermiso] = parts;
        if (!permisosObj[mainPermiso] || typeof permisosObj[mainPermiso] !== 'object') {
          permisosObj[mainPermiso] = {};
        }
        permisosObj[mainPermiso][subPermiso] = true;
      } else if (parts.length === 3) {
        const [mainPermiso, subPermiso, subSubPermiso] = parts;
        if (!permisosObj[mainPermiso] || typeof permisosObj[mainPermiso] !== 'object') {
          permisosObj[mainPermiso] = {};
        }
        if (!permisosObj[mainPermiso][subPermiso] || typeof permisosObj[mainPermiso][subPermiso] !== 'object') {
          permisosObj[mainPermiso][subPermiso] = {};
        }
        permisosObj[mainPermiso][subPermiso][subSubPermiso] = true;
      }
    });
  
    return permisosObj;
  };

  const transferData = () => {
    const formattedPermisos = [];
    const formattedPermisosActuales = [];

    Object.keys(defaultPermisos).forEach((key) => {
      const permiso = defaultPermisos[key];

      if (typeof permiso === "object" && !Array.isArray(permiso)) {
        Object.keys(permiso).forEach((subKey) => {
          const permiso = defaultPermisos[key][subKey];

          if (typeof permiso === "object" && !Array.isArray(permiso)) {
            Object.keys(permiso).forEach((thirdKey) => {
              formattedPermisos.push({
                key: `${key}-${subKey}-${thirdKey}`,
                title: `${key} - ${subKey} - ${thirdKey}`,
              });
            });
          } else {
            formattedPermisos.push({
              key: `${key}-${subKey}`,
              title: `${key} - ${subKey}`,
            });
          }
        });
      } else {
        formattedPermisos.push({
          key: key,
          title: key,
        });
      }
    });

    if (permisos && permisos !== null) {
      Object.keys(permisos).forEach((key) => {
        const permiso = permisos[key];
  
        if (typeof permiso === "object" && !Array.isArray(permiso)) {
          Object.keys(permiso).forEach((subKey) => {
            const permiso = permisos[key][subKey];
  
            if (typeof permiso === "object" && !Array.isArray(permiso)) {
              Object.keys(permiso).forEach((thirdKey) => {
                formattedPermisosActuales.push({
                  key: `${key}-${subKey}-${thirdKey}`,
                  title: `${key} - ${subKey} - ${thirdKey}`,
                });
              });
            } else {
              formattedPermisosActuales.push({
                key: `${key}-${subKey}`,
                title: `${key} - ${subKey}`,
              });
            }
          });
        } else {
          formattedPermisosActuales.push({
            key: key,
            title: key,
          });
        }
      });
    }

    formattedPermisos.filter(
      (permiso) => !formattedPermisosActuales.find((p) => p.key === permiso.key)
    );

    return {
      allPermisos: formattedPermisos.sort((a, b) =>
        a.title.localeCompare(b.title)
      ),
      targetPermisos: formattedPermisosActuales.sort((a, b) =>
        a.title.localeCompare(b.title)
      ),
    };
  };

  const { allPermisos, targetPermisos } = transferData();

  return (
    <>
      <Row>
        <Col xs={24} md={24} lg={24}>
          <Title level={3} className="ccg-center">
            Configurar Puestos
          </Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={8} lg={8}>
          <Suspense fallback={<Spin tip="Cargando sucursales..." />}>
            <Sucursales onSucursalChange={handleSucursalChange} />
          </Suspense>
          {selectedSucursal && (
            <>
              <Row style={{ marginBottom: 20 }}>
                <Col xs={24} md={12}>
                  <Input
                    placeholder="Nuevo Puesto"
                    value={newDepartmentName}
                    onChange={(e) => setNewDepartmentName(e.target.value)}
                  />
                </Col>
                <Col xs={24} md={{ span: 6, offset: 1 }}>
                  <Button type="primary" onClick={handleAddDepartment}>
                    Agregar Puesto
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24}>
                  <Tree style={{ marginTop: 10 }} onSelect={handleSelect}>
                    {renderTreeNodes(departments)}
                  </Tree>
                </Col>
              </Row>
              {selectedDepartment && (
                <Row style={{ marginTop: 20 }}>
                  <Col xs={24} md={12}>
                    <Input
                      placeholder="Agregar Área"
                      value={newArea}
                      onChange={(e) => setNewArea(e.target.value)}
                    />
                  </Col>
                  <Col xs={24} md={{ span: 6, offset: 1 }}>
                    <Button type="primary" onClick={handleAddArea}>
                      Agregar Área
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
        <Col xs={24} md={{ span: 12, offset: 2 }} lg={{ span: 12, offset: 2 }}>
          {selectedDepartment && (
            <>
              <Title level={5} className="ccg-center">
                Permisos de{" "}
                {selectedArea && selectedArea.includes("-")
                  ? selectedArea.replace("-", " -> ")
                  : selectedArea}
              </Title>
              <Transfer
                showSearch
                titles={["Permisos disponibles", "Permisos asignados"]}
                dataSource={allPermisos}
                targetKeys={targetPermisos.map((permiso) => permiso.key)}
                render={(item) => item.title}
                onChange={handleTransferChange}
                listStyle={{
                  width: "100%",
                  height: "50vh",
                }}
                locale={{
                  itemUnit: "elemento",
                  itemsUnit: "elementos",
                  searchPlaceholder: "Buscar",
                  notFoundContent: "Sin permisos",
                  remove: "Eliminar",
                  selectCurrent: "Seleccionar página actual",
                  selectAll: "Seleccionar todo",
                  deselectAll: "Deseleccionar todo",
                  selectInvert: "Invertir selección",
                }}
                filterOption={(inputValue, option) =>
                  option.title.indexOf(inputValue) > -1
                }
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ConfigurarPuesto;
