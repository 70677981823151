import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Row, Col, Divider, Steps } from "antd";
// import Servicios from "./Servicios";
import Clientes from "../clientes/Clientes";
import AgregarProyectoConcreto from "./AgregarProyectoConcreto";
import AgregarProyectoAsfalto from "./AgregarProyectoAsfalto";
import Title from "antd/es/typography/Title";

const { Step } = Steps;

const AgregarProyecto = () => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [proyecto, setProyecto] = useState(null);

  const handleProjectChange = (checkedValues) => {
    setSelectedProjects(checkedValues);
  };

  const steps = [
    {
      title: "Información Básica",
      content: (
        <>
          <Row gutter={16}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Nombre del Proyecto"
                name="nombre_proyecto"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el nombre del proyecto",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Clientes />
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Servicios",
      content: <Title>Servicios</Title>,
    },
    {
      title: "Seleccionar Proyectos",
      content: (
        <>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={handleProjectChange}
          >
            <Row>
              <Col span={6}>
                <Checkbox value="concreto">Concreto</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="asfalto">Asfalto</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="estructura_mecanica">
                  Estructura Mecánica
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value="terraceria">Terracería</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </>
      ),
    },
    {
      title: "Detalle de Proyectos",
      content: (
        <>
          {selectedProjects.includes("concreto") && (
            <>
              <Divider>Proyecto de Concreto</Divider>
              <AgregarProyectoConcreto />
            </>
          )}
          {selectedProjects.includes("asfalto") && (
            <>
              <Divider>Proyecto de Asfalto</Divider>
              <AgregarProyectoAsfalto />
            </>
          )}
          {/* Agrega más componentes de subproyectos aquí */}
        </>
      ),
    },
    {
      title: "Resumen",
      content: proyecto && (
        <>
          <div>
            <h3>Resumen del Proyecto</h3>
            <p>
              <strong>Nombre del Proyecto:</strong> {proyecto.nombre_proyecto}
            </p>
            <p>
              <strong>Cliente:</strong> {proyecto.cliente}
            </p>
            <p>
              <strong>Servicios:</strong> {proyecto.nombre_servicio} -{" "}
              {proyecto.descripcion_servicio}
            </p>
            {proyecto.tipo_concreto && (
              <>
                <h4>Proyecto de Concreto</h4>
                <p>
                  <strong>Tipo de Concreto:</strong> {proyecto.tipo_concreto}
                </p>
                <p>
                  <strong>Volumen de Concreto:</strong>{" "}
                  {proyecto.volumen_concreto} m³
                </p>
              </>
            )}
            {proyecto.tipo_asfalto && (
              <>
                <h4>Proyecto de Asfalto</h4>
                <p>
                  <strong>Tipo de Asfalto:</strong> {proyecto.tipo_asfalto}
                </p>
                <p>
                  <strong>Volumen de Asfalto:</strong>{" "}
                  {proyecto.volumen_asfalto} m³
                </p>
              </>
            )}
            {/* Agrega más subproyectos aquí */}
          </div>
          )
        </>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleFinish = (values) => {
    setProyecto(values);
    next();
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Anterior
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Siguiente
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            Finalizar Proyecto
          </Button>
        )}
      </div>
    </Form>
  );
};

export default AgregarProyecto;
